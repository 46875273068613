import { Routes, Route,Navigate} from "react-router-dom"
import Footer from "../Footer/Footer";
import jwtDecode from 'jwt-decode';
import UserDashboard from "../UserDashboard/UserDashboard";
import NavBar from "../Navbar/Navbar";
import Header from "../Header/Header";
import UserCTFDashboard from "../UserCTFDashboard/UserCTFDashboard";
import UserAssignedChallenges from "../UserAssignedChallenges/UserAssignedChallenges";
import AttemptedChallengesByUser from "../AttemptedChallengesByUser/AttemptedChallengesByUser";
import UserVideoTutorial from "../VideoTutorial/UserVideoTutorial";
import UserScoreBoard from "../UserScoreBoard/UserScoreBoard";
import UserUtil from "../UserUtil/UserUtil";


const QuizResponseRouter = () => {
  
    // const LoggedIn= sessionStorage.getItem("LoggedIn");
    const LoggedIn = UserUtil.getSessionStorage("IsLoggedIn");
    const tok = UserUtil.getSessionStorage("_txyz");
    let roles="";
    let isAuthenticated="";
    let isUser="";
    if (LoggedIn === "true" && tok){
    // isAuthenticated=sessionStorage.getItem("token");
    isAuthenticated=UserUtil.getSessionStorage("_txyz");
    roles = getRolesFromToken(isAuthenticated);
                            
    function getRolesFromToken(token) {
        // Decode the JWT token
          const decodedToken = jwtDecode(token);

         // Extract the username from the decoded token
         const roles = decodedToken ? decodedToken.roles : null;

         return roles;
     }
  

     isUser = roles === "[ROLE_USER]";
    }
    return isAuthenticated  && isUser ? (
        <div>
            <Header />
            <Routes >
                {/* <Route path="/" element={<QuizResponse />} /> */}
                {/* <Route path="/user-dashboard" element={<UserDashboard />} />
                <Route path="/:qId" element={<QuizResponse />} />
                <Route path="/result/:uId" element={<QuizResult />} />
                <Route path="/quiz-dashboard" element={<UserQuizDashboard/>} /> 
                <Route path="/quiz-attempted-challenges" element={<UserAttemptedQuizzes/>} />                 */}
                <Route path="/ctf" element={<UserCTFDashboard/>} />
                <Route path="/ctf-challenges" element={<UserAssignedChallenges/>} />
                <Route path="/ctf-attempted-challenges" element={<AttemptedChallengesByUser/>} />
                <Route path="/user-video-tutorial" element={<UserVideoTutorial />} />
                <Route path="/user-scoreboard" element={<UserScoreBoard />} />
            </Routes>
            {/* <Footer /> */}
        </div>
    ) : (
        // <Navigate to="/error" />
        <Navigate to="/" />
    );
}


export default QuizResponseRouter;