import '../assets/Style.css'
import '../assets/css/style.css'
import { useState, useEffect } from 'react';
import { API_BASE_URL, IVP_BASE_URL } from "../config/config";
import Nav from 'react-bootstrap/Nav';
import $ from 'jquery';
import Services from '../../src/Service/Service';
import DataTable from 'react-data-table-component';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import jwtDecode from 'jwt-decode';
import Badge from 'react-bootstrap/Badge';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import swal from "sweetalert"
import Button from 'react-bootstrap/Button';
import { Modal } from 'react-bootstrap';
import certificate from '../assets/img/certificate.png'
import UserUtil from '../UserUtil/UserUtil';


const UserAssignedChallenges = () => {
    let navigate = useNavigate();
    const [leaderboardData, setLeaderboardData] = useState([]);
    const [solveCount, setSolveCount] = useState(0);
    const [cardAttempts, setCardAttempts] = useState({});
    const [flagValid, setFlagValid] = useState(false);
    const [ctfName, setCtfName] = useState('');
    const [flagErrorMessage, setFlagErrorMessage] = useState('');
    const [selectedHintIndex, setSelectedHintIndex] = useState(-1);
    const [rotationAngle, setRotationAngle] = useState(0);
    const [challengeData, setChallengeData] = useState([{}]);
    const [challengeSolvesData, setChallengeSolvesData] = useState([{}]);
    const [selectedHints, setSelectedHints] = useState([]);
    const [openedHints, setOpenedHints] = useState([]);
    const [activeTab, setActiveTab] = useState('Challenge');
    const [flagValue, setFlagValue] = useState('');
    const [getHint, setHint] = useState(null);
    const [showConfirmationAlert, setShowConfirmationAlert] = useState(false);
    const [getChallengeId, setChallengeId] = useState(null);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [selectedHint, setSelectedHint] = useState(null);
    const [clickedChallenge, setClickedChallenge] = useState(null);
    const [attempts, setAttempts] = useState(0);
    const { ctfId } = useParams();
    const [getAttemptErrorMessage, setAttemptErrorMessage] = useState('')
    const [showFilePreview, setShowFilePreview] = useState(false);
    const [fileToPreview, setFileToPreview] = useState('');
    const [selectedChallengeId, setSelectedChallengeId] = useState('');
    const [filePreviewName, setFilePreviewName] = useState('');
    const [hintClickCounts, setHintClickCounts] = useState({});
    const [show, setShow] = useState(false);
    const location = useLocation();
    const challengeListId = location.state ? location.state.challengeListId : null;


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleFilePreviewClick = (fileUrl, fileName) => {
        setFileToPreview(fileUrl);
        setShowFilePreview(true);

    };

    const closeModal = () => {
        setShowFilePreview(false);
        setFileToPreview('');
    };

    const handleClose1 = () => {
        setShow1(false);

    }
    const handleShow1 = () => setShow1(true);
    const handleShow2 = (clickedChallenge) => {
        setShow2(true);
        setChallengeId(clickedChallenge.challengeId);
        setClickedChallenge(clickedChallenge);
        setSelectedHintIndex(null)
        setHintClickCounts({})

    }


    const handleTabChange = (eventKey) => {
        setActiveTab(eventKey);
    };


    const handleClose2 = () => {
        setShow2(false);
        setActiveTab("Challenge");
        setSelectedHints([]);
        setOpenedHints([]);
        setFlagValue('');
        setChallengeId(null);
        setAttempts(0);
        setChallengeSolvesData([{}])
        setClickedChallenge(null);
        setAttemptErrorMessage('');
        setFlagErrorMessage('');
    }



    const handleCardClick = (challengeId) => {
        // Reset attempts for the clicked card and set it to 0
        setCardAttempts({
            ...cardAttempts,
            [challengeId]: 0,
        });
        // Other logic or actions when a card is clicked can go here
    };

    const columnsChallenge = [
        {
            name: 'Sr No',
            selector: (row, index) => index + 1,
            center: true,

        },
        {
            name: 'Challenge Name',
            selector: (row) => row.selectedChallenge,
            cell: row => <span className="text-capitalize">{row.selectedChallenge}</span>,
            center: true,

            wrap: true,
            compact: true,
        },
        {
            name: 'CTF Name',
            selector: (row) => row.challengeListName,
            cell: row => <span className="text-capitalize">{row.challengeListName}</span>,
            center: true,
        },

        {
            name: 'Flag',
            selector: (row) => row.flag,
            center: true,
            wrap: true,
            compact: true,
        },
        {
            name: 'Score',
            selector: (row) => row.value,
            center: true,
        },
        {
            name: 'Secured Score',
            selector: (row) => <Badge pill bg="primary" className='px-4'>{row.scoredValue}</Badge>,
            center: true,
        },
        {
            name: 'Timestamp',
            selector: (row) => row.createdDate,
            center: true,
            maxWidth: '100px',
            wrap: true,
            compact: true,
        }

    ]


    // console.log("Attempts", attempts);

    // const handleChallengeListClick = (challengeList) => {
    //     setSelectedChallengeList(challengeList);
    //     setDisplayChallengeLists(false);
    //     setBreadcrumbText('Assigned Challenges');
    //     setHeadingText('Assigned Challenges')
    //     // Make the API call to GetLeaderBoard with the selected challenge list
    //     Services.GetLeaderBoard(challengeList.challengeListId)
    //         .then(response => {
    //             setLeaderboardData(response.data);
    //         })
    //         .catch(err => {
    //             if (err.response && err.response.status === 401) {
    //                 const errorMessage = err.response.data.errors.UnAuthorized;
    //                 swal("Message!", errorMessage, "warning");
    //                 Services.Logout()
    //                     .then(response => {
    //                         if (response.status === 200) {
    //                             sessionStorage.removeItem("token");
    //                             sessionStorage.removeItem("LoggedIn");
    //                             navigate("/");
    //                         }
    //                     })
    //                     .catch(err => {
    //                         // Handle error if needed
    //                     });
    //             }
    //         });
    // };




    // const LoggedIn = sessionStorage.getItem("LoggedIn");
    const LoggedIn = UserUtil.getSessionStorage("IsLoggedIn");
    const tok = UserUtil.getSessionStorage("_txyz");
    let email = "";
    let username = "";
    let ivp_user_id = "";
    if (LoggedIn === "true" && tok) {
        // const token = sessionStorage.getItem("token");
        const token = UserUtil.getSessionStorage("_txyz");
        ivp_user_id = UserUtil.getSessionStorage("__ivpflag");
        email = getEmailFromToken(token);
        username = getUsernameFromToken(token);

        function getEmailFromToken(token) {

            const decodedToken = jwtDecode(token);

            const email = decodedToken ? decodedToken.sub : null;

            return email;
        }


        function getUsernameFromToken(token) {

            const decodedToken = jwtDecode(token);

            const username = decodedToken ? decodedToken.username : null;

            return username;
        }

    }





    // console.log("fsd", clickedChallenge);




    const renderNoCertificate = (props) => (
        <Tooltip id="noCertificate" {...props}>
            No Certificate issued
        </Tooltip>
    );

    const customStyles = {

        headCells: {
            style: {
                fontSize: '14px',

                //paddingLeft: '0 8px',
                //marginLeft: '10px',
                fontWeight: 'bold',
            },
        },
        cells: {
            style: {
                fontSize: '15px',
                wrap: 'false',

            },
        },
        headRow: {
            style: {
                backgroundColor: '#ced0f0',
                color: 'black',
                borderRadius: '5px'
            },
        },
    };






    const handleFileDownload = (fileUrl, fileName) => {

        // Create an invisible link element to trigger the download
        const link = document.createElement('a');
        link.href = fileUrl;
        link.target = '_blank'; // Open the link in a new tab if needed
        link.setAttribute('download', ''); // This sets the download attribute to prompt for download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Clean up the temporary link
    };


    // const handleHintClick = (hintIndex, hintData) => {
    //     // Set the selected hint
    //     setSelectedHint({
    //         hintIndex,
    //         hintData,
    //     });
    // }

    const columns = [
        {
            name: 'Username',
            selector: (row) => row.username,
            center: true
        },
        {
            name: 'Score',
            selector: (row) => row.score,
            center: true,

        },
        {
            name: 'Time Stamp',
            selector: (row) => row.Timestamp,
            maxWidth: '1200px',
            wrap: true,
            compact: true,
            grow: false,

        },
    ];


    const handleFlagChange = (event) => {
        const value = event.target.value;
        setFlagValue(event.target.value);
        if (value.trim() === '') {
            setFlagValid(true);
            setFlagErrorMessage('Flag is required');
        } else {
            setFlagValid(false);
            setFlagErrorMessage('');
        }
    };


    const onsubmitChallengeResponse = () => {
        const hintContents = openedHints.map(openedHint => openedHint.hint);

        const data = {
            challengeId: getChallengeId,
            flag: flagValue,
            hints: hintContents,
            challengeListId: challengeListId,
            ivpUser_id: ivp_user_id
            // email: email,
        };

        if (!flagValue) {
            // console.log('Flag is not valid');
            setFlagErrorMessage('Flag is required');
            return;
        }


        // console.log("clickedChallenge.noOfAttempts", clickedChallenge.noOfAttempts);
        // console.log("attempts", attempts);
        if (attempts < clickedChallenge.noOfAttempts || clickedChallenge.noOfAttempts === 0) {
            Services.ChallengeResponseAttend(data)
                .then(async response => {
                    if (response.status === 200) {
                        await swal("Success", response.data.msg, "success");
                        handleClose2();
                        // navigate("/dashboard/view-challenge-list");
                        setCardAttempts({
                            ...cardAttempts,
                            [clickedChallenge.challengeId]: attempts + 1,
                        });
                    }
                    else {
                        await swal("Message", response.data.msg, "info")
                    }
                }).catch(err => {
                    if (err.response && err.response.status === 401) {
                        const errorMessage = err.response.data.errors.UnAuthorized;

                        swal("Message!", errorMessage, "warning");
                        UserUtil.logout();
                    }
                    else if (err.response && err.response.data && err.response.data.errors) {
                        let errorMessages = Object.values(err.response.data.errors).join('\n');
                        // swal("Message!", errorMessages, "warning");
                        setAttemptErrorMessage(errorMessages);

                    } else if (err.response && err.response.data && err.response.data.msg) {
                        swal("Message!", err.response.data.msg, "warning");
                    } else {
                        swal("Message!", "Server error occurred", "warning");
                    }
                });

        } else {
            setAttemptErrorMessage("Maximum number of Attempts are completed");
            setCardAttempts({
                ...cardAttempts,
                [clickedChallenge.challengeId]: 0,
            });
        }
    }



    const toggleHintSelection = (hintIndex, hintData) => {
        // console.log("click", hintData)
        const indexOfHint = openedHints.findIndex(hint => hint.index === hintIndex);

        if (indexOfHint !== -1) {
            // If the hint is already opened, close it
            const updatedHints = [...openedHints];
            updatedHints.splice(indexOfHint, 1);
            setOpenedHints(updatedHints);
            setSelectedHintIndex(-1);
        } else {
            const clickCount = hintClickCounts[hintIndex] || 0;

            if (clickCount === 0) {
                // If the hint is not opened, show a confirmation dialog
                const isConfirmed = window.confirm("Are you sure you'd like to unlock this hint?");

                if (isConfirmed) {
                    Services.GetHintData(getChallengeId, hintIndex)
                        .then(async response => {
                            const hintData = response.data[hintIndex];

                            if (hintData) {
                                const hintValue = hintData.value;
                                const hintContent = hintData.hint;
                                setHint(hintContent);
                                const updatedHints = [...openedHints, { index: hintIndex, hint: hintContent }];
                                setOpenedHints(updatedHints);
                                setSelectedHintIndex(hintIndex);
                                // setClickedHints([...clickedHints, hintIndex]);
                                const updatedClickCounts = {
                                    ...hintClickCounts,
                                    [hintIndex]: clickCount + 1,
                                };
                                setHintClickCounts(updatedClickCounts);


                            }



                        }).catch(err => {
                            if (err.response && err.response.status === 401) {
                                const errorMessage = err.response.data.errors.UnAuthorized;

                                swal("Message!", errorMessage, "warning");
                                UserUtil.logout();
                            }
                            else if (err.response && err.response.data && err.response.data.errors) {
                                let errorMessages = Object.values(err.response.data.errors).join('\n');
                                // swal("Message!", errorMessages, "warning");
                                setAttemptErrorMessage(errorMessages);
                            } else if (err.response && err.response.data && err.response.data.msg) {
                                swal("Message!", err.response.data.msg, "warning");
                            } else {
                                swal("Message!", "Server error occurred", "warning");
                            }
                        });
                }
            } else {
                Services.GetHintData(getChallengeId, hintIndex)
                    .then(async response => {
                        const hintData = response.data[hintIndex];

                        if (hintData) {
                            const hintValue = hintData.value;
                            const hintContent = hintData.hint;
                            setHint(hintContent);
                            const updatedHints = [...openedHints, { index: hintIndex, hint: hintContent }];
                            setOpenedHints(updatedHints);
                            setSelectedHintIndex(hintIndex);
                            // setClickedHints([...clickedHints, hintIndex]);
                            const updatedClickCounts = {
                                ...hintClickCounts,
                                [hintIndex]: clickCount + 1,
                            };
                            setHintClickCounts(updatedClickCounts);


                        }



                    }).catch(err => {
                        if (err.response && err.response.status === 401) {
                            const errorMessage = err.response.data.errors.UnAuthorized;

                            swal("Message!", errorMessage, "warning");
                            UserUtil.logout();
                        }
                        else if (err.response && err.response.data && err.response.data.errors) {
                            let errorMessages = Object.values(err.response.data.errors).join('\n');
                            // swal("Message!", errorMessages, "warning");
                            setAttemptErrorMessage(errorMessages);
                        } else if (err.response && err.response.data && err.response.data.msg) {
                            swal("Message!", err.response.data.msg, "warning");
                        } else {
                            swal("Message!", "Server error occurred", "warning");
                        }
                    });
            }
        }
        // const isSelected = selectedHints.some((hint) => hint.index === hintIndex);

        // if (!isSelected) {           
        //     setSelectedHints((prevSelectedHints) => [
        //         ...prevSelectedHints,
        //         { index: hintIndex, hint: hintData.hint },
        //     ]);
        // }
    };

    //     // const isSelected = selectedHints.some((hint) => hint.index === hintIndex);

    //     // if (!isSelected) {           
    //     //     setSelectedHints((prevSelectedHints) => [
    //     //         ...prevSelectedHints,
    //     //         { index: hintIndex, hint: hintData.hint },
    //     //     ]);
    //     // }
    // };



    // const fetchAndDisplayHint = () => {

    //     Services.GetHintData(getChallengeId, selectedHintIndex)
    //         .then(async response => {
    //             const hintData = response.data[selectedHintIndex];

    //             if (hintData) {
    //                 const hintContent = hintData.hint;
    //                 setHint(hintContent);
    //                 setSelectedHint(hintContent);
    //             }
    //             setShow(false);

    //         })
    //         .catch(err => {
    //             if (err.response && err.response.status === 401) {
    //                 const errorMessage = err.response.data.errors.UnAuthorized;

    //                 swal("Message!", errorMessage, "warning");
    //                 Services.Logout()
    //                     .then(response => {
    //                         if (response.status === 200) {
    //                             sessionStorage.removeItem("token");
    //                             sessionStorage.removeItem("LoggedIn");
    //                             navigate("/");
    //                         }
    //                     })
    //                     .catch(err => {
    //                         // Handle error if needed
    //                     });
    //             }
    //             else if (err.response && err.response.data && err.response.data.errors) {
    //                 let errorMessages = Object.values(err.response.data.errors).join('\n');
    //                 // swal("Message!", errorMessages, "warning");
    //                 setAttemptErrorMessage(errorMessages);
    //             } else if (err.response && err.response.data && err.response.data.msg) {
    //                 swal("Message!", err.response.data.msg, "warning");
    //             } else {
    //                 swal("Message!", "Server error occurred", "warning");
    //             }
    //         })

    // };


    const renderTabContent = () => {
        // Render content based on the active tab      
        // console.log("attemptssss",attempts);
        if (activeTab === 'Challenge' && clickedChallenge) {
            const challenge = clickedChallenge;

            const isFilesPresent = Array.isArray(challenge.files) && challenge.files.length > 0;
            const fileNames = isFilesPresent ? challenge.files.map(url => url.substring(url.lastIndexOf('/') + 1)) : [];
            const isHintAvailable = challenge.hints && Object.keys(challenge.hints).length > 0;

            // console.log("HINTS", challenge.hints)


            return <div className='Quiz-admin container-fluid'>
                <h4 className='text-center text-capitalize'>{clickedChallenge ? clickedChallenge.challengeName : ''}</h4>
                <h4 className='text-center'>Score : {clickedChallenge ? clickedChallenge.value : ''}</h4>
                <p dangerouslySetInnerHTML={{ __html: clickedChallenge ? clickedChallenge.description : '' }}></p>
                {clickedChallenge && clickedChallenge.link && (
                    <a href={clickedChallenge.link} target="_blank" rel="noopener noreferrer">
                        {clickedChallenge.link}
                    </a>
                )}
                {isHintAvailable && (
                    <div>
                        {Object.entries(challenge.hints).map(([hintIndex, hintData]) => (
                            <>

                                <div className="alert alert-dark fw-bold mt-1" role="alert" onClick={() => toggleHintSelection(hintIndex, hintData)}>
                                    <span className="arrow-icon">
                                        <i className="bi bi-caret-right-fill text-white"></i>
                                    </span>
                                    View Hint {hintIndex}&nbsp;
                                    (Using a hint will result in a deduction of {hintData.value} points)
                                </div>
                                <div>
                                    {getHint && selectedHintIndex === hintIndex && (
                                        <div className="accordion-body p-0">
                                            <h6 style={{ display: 'inline-flex' }} className='fw-bold px-4'>
                                                <i className='bx bxs-hand-right'></i>
                                                <span dangerouslySetInnerHTML={{ __html: ` ${getHint}` }}></span>
                                            </h6>
                                        </div>
                                    )}
                                </div>
                            </>
                        ))}
                    </div>
                )}

                {fileNames.map((fileName, index) => (
                    // <button
                    //     key={index}
                    //     className="btn btn-secondary btn-sm m-1" onClick={() => handleFileDownload(challenge.files[index], fileName)}
                    // >
                    //     <i className="fas fa-download p-1"></i>{fileName}
                    // </button>
                    <div>
                    <button
                        key={index}
                        // download={fileName}
                        className="btn btn-secondary btn-sm m-1"
                        // href={challenge.files[index]}
                        onClick={() => handleFilePreviewClick(challenge.files[index])} // Call the function to open the modal
                    >
                        {fileName}
                    </button>
                    </div>

                ))}

                {/* {clickedChallenge.noOfAttempts > 0 && (
                    <div className='text-center my-3'>
                        <p>{attempts}/{clickedChallenge.noOfAttempts} attempts</p>
                    </div>)} */}

                <div className='row'>
                    <div className='col-md-9'>
                        <input
                            type="text"
                            className={flagErrorMessage ? "form-control is-invalid" : "form-control"}
                            id="flag"
                            name='flag'
                            placeholder='Flag'
                            autoComplete="off"
                            value={flagValue}
                            onChange={handleFlagChange}
                        />

                        <div className="invalid-feedback">
                            {flagErrorMessage && <span>{flagErrorMessage}</span>}
                        </div>
                    </div>




                    {/* <div className='col-md-4 text-center mt-2'>
                        <button type="button" className="btn btn-success" disabled={attempts >= clickedChallenge.noOfAttempts && clickedChallenge.noOfAttempts > 0} onClick={onsubmitChallengeResponse}>Submit</button>
                    </div> */}
                    <div className='col-md-3 text-end mt-2'>
                        <button type="button" className="btn btn-success" onClick={onsubmitChallengeResponse}>Submit</button>
                    </div>

                </div>
                {/* {attempts >= clickedChallenge.noOfAttempts && clickedChallenge.noOfAttempts !== 0 && (
                    <div className="alert alert-danger fw-bold text-center" role="alert">
                        You have no tries remaining
                    </div>

                )} */}

                {getAttemptErrorMessage && <div className='alert alert-danger fw-bold text-center mt-2'>
                    {getAttemptErrorMessage}
                </div>}

            </div>
        } else if (activeTab === 'Solves') {
            const challenge = clickedChallenge;
            if (getChallengeId !== clickedChallenge.challengeId) {
                setChallengeId(clickedChallenge.challengeId);
            }

            return <div className='mt-4'>
                <DataTable
                    columns={columnsChallenge}
                    data={challengeSolvesData}
                    highlightOnHover
                    pointerOnHover
                    responsive
                    // pagination
                    // paginationPerPage={10}
                    customStyles={customStyles}
                />

            </div>;
        }
    }



    useEffect(() => {
        Services.GetChallengeUserListById(challengeListId)
            .then(response => {
                const challengeListData = response.data[0];
                const challenges = challengeListData.challenges;
                const ctfName = challengeListData.challengeListName;
                // console.log("name", ctfName);
                setCtfName(ctfName);
                setChallengeData(prevChallengeData => ({ ...prevChallengeData, challenges }));

            }).catch((err) => {
                if (err.response && err.response.status === 401) {
                    const errorMessage = err.response.data.errors.UnAuthorized;

                    swal("Message!", errorMessage, "warning");
                    UserUtil.logout();
                }else if (err.response && err.response.status === 400) {
                    let errorMessages = Object.values(err.response.data.errors).join('\n');
                    swal("Message!", errorMessages, "warning");
                } else {
                    swal("Message!", "Server error occurred", "warning");
                }
                // alert("Server Error 500")
            })
    }, []);


    useEffect(() => {
        if (activeTab === 'Solves') {
            Services.GetChallengeResponseInSolves(getChallengeId)
                .then(response => {
                    setChallengeSolvesData(response.data)

                }).catch((err) => {
                    if (err.response && err.response.status === 401) {
                        const errorMessage = err.response.data.errors.UnAuthorized;

                        swal("Message!", errorMessage, "warning");
                        UserUtil.logout();
                    }
                    else {
                        swal("Message!", "Server error occurred", "warning");
                    }
                    // alert("Server Error 500")
                })
        }
    }, [activeTab]);



    const fetchLeaderBoardData = () => {
        Services.GetLeaderBoard(challengeListId)
            .then(response => {
                setLeaderboardData(response.data);

            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    const errorMessage = err.response.data.errors.UnAuthorized;
                    swal("Message!", errorMessage, "warning");
                    UserUtil.logout();
                }
            });
    };


    useEffect(() => {
        fetchLeaderBoardData(); // Fetch data initially

        const intervalId = setInterval(() => {
            fetchLeaderBoardData(); // Fetch data every 30 seconds
        }, 10000); // 10 seconds in milliseconds

        // Clean up the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, []);


    // const handleRotateIconClick = () => {
    //     fetchLeaderBoardData();
    //     setRotationAngle(rotationAngle + 360);
    // };

    const challengesByCategory = {};





    const displayedChallengeNames = {};
    if (challengeData && challengeData.challenges) {
        challengeData.challenges.forEach(challenge => {
            const { category, challengeName } = challenge;
            if (!challengesByCategory[category]) {
                challengesByCategory[category] = [];
            }

            // Check if the challenge name has not been displayed in this category
            if (!displayedChallengeNames[category]?.has(challengeName)) {
                challengesByCategory[category].push(challenge);

                // Add the challenge name to the set to mark it as displayed
                if (!displayedChallengeNames[category]) {
                    displayedChallengeNames[category] = new Set();
                }
                displayedChallengeNames[category].add(challengeName);
            }

            challengesByCategory[category].forEach((challenge, index) => {
                switch (index % 4) {
                    case 0:
                        challenge.hoverColorClass = 'color1';
                        break;
                    case 1:
                        challenge.hoverColorClass = 'color2';
                        break;
                    case 2:
                        challenge.hoverColorClass = 'color3';
                        break;
                    case 3:
                        challenge.hoverColorClass = 'color4';
                        break;
                    default:
                        challenge.hoverColorClass = ''; // Default hover color class
                }
            });
        });


    }

    const leaderboardArray = Object.values(leaderboardData);

    const hasLeaderboardData = leaderboardArray.length > 0;

    // console.log("leaderboardArray:", leaderboardArray);
    // console.log("columns:", filePreviewName);


    const extractImageNameFromUrl = (imageUrl) => {
        const parts = imageUrl.split('/');
        return parts[parts.length - 1];
    };

    const downloadFile = (fileToPreview) => {
        // Make an API request to download the image using the 'imageName'
        // You can use your 'Services' to handle this API call
        const fileName = extractImageNameFromUrl(fileToPreview);
        Services.DownloadFileinChallenge(fileName)
            .then(response => {
                if (response.data && response.headers['content-type']) {
                    // Extract the content type from the response headers
                    const contentType = response.headers['content-type'];

                    // Create a blob from the response data with the correct content type
                    const blob = new Blob([response.data], { type: contentType });

                    // Create a link element to trigger the download
                    const a = document.createElement('a');
                    a.href = API_BASE_URL + `/download-file/${fileName}`; // Set the desired download URL
                    a.download = fileName; // You can specify the desired filename here
                    document.body.appendChild(a);

                    // Trigger the download
                    a.click();

                    // Clean up
                    document.body.removeChild(a);
                    swal("Success", "File downloaded successfully", "success");

                }
            })
            .catch(err => {
                // Handle any errors that occur during the API request
                if (err.response && err.response.status === 401) {
                    const errorMessage = err.response.data.errors.UnAuthorized;
                    swal("Message!", errorMessage, "warning");
                    UserUtil.logout();
                } else if (err.response && err.response.data && err.response.data.errors) {
                    let errorMessages = Object.values(err.response.data.errors).join('\n');
                    swal("Message!", errorMessages, "warning");
                } else if (err.response && err.response.data && err.response.data.msg) {
                    swal("Message!", err.response.data.msg, "warning");
                } else {
                    swal("Message!", "Server error occurred", "warning");
                }
            });
    };




    return (
        <div className='user-dashboard'>
            <section className="breadcrumbs">
                <div className="container mt-3">

                    <ol>
                        <li>
                            <Link to="/">
                                <i class="bi bi-house-door-fill"></i>
                                Home
                            </Link>
                        </li>



                        <li>
                            <Link to="/user-dashboard/ctf">
                                User Dashboard
                            </Link>
                        </li>
                        {/* <li>
                            Challenges
                        </li> */}


                    </ol>
                    <h2 className='text-capitalize'>{ctfName} Challenges</h2>

                </div>
            </section>
            <div className='container'>
                {/* <h2>{ctfName}</h2> */}
                {/* <header className="section-header">
                    <p className='fs-4 text-capitalize'>{ctfName}</p>
                </header> */}

                <div className='alert alert-primary mt-3'>
                    <h5 className="alert-heading fw-bold">Instructions:</h5>
                    <ul className='fw-bold'>
                        <li>
                            Each challenge within the CTF will come with a specific number of attempts and its corresponding score.
                        </li>
                        <li>
                            Some challenges may offer hints for solving, but accessing these hints will result in a reduction of marks.
                        </li>
                        <li>
                            The number of attempts made by the user on a specific challenge can be viewed in the 'solve' tab when accessing the respective challenge.

                        </li>
                        <li>
                            The leaderboard will be updated every 10 seconds.
                        </li>
                    </ul>
                </div>
                <div className='about'>
                    <div className='content p-4 mb-3'>
                        <div className="col-lg-12">

                            <div className="row">
                                <div className='col-md-6'>
                                    <div>
                                        {Object.keys(challengesByCategory).map(category => (
                                            <div key={category}>
                                                <div className='about'>
                                                    <h4 className='text-capitalize fw-bold'>{category}</h4>
                                                </div>
                                                <div className="row services-assignedchallenge">
                                                    {challengesByCategory[category].map(challenge => (
                                                        <div
                                                            className="col-md-4 d-flex align-items-stretch"
                                                            key={challenge.challengeId}
                                                            onClick={() => {
                                                                handleCardClick(challenge.challengeId);
                                                                handleShow2(challenge);
                                                            }}
                                                        >
                                                            <div className="icon-box my-3">
                                                                <h3 className='text-capitalize text-center'> {challenge.challengeName}</h3>
                                                                <p className="small">
                                                                    <div className="quiz-info d-flex fw-bold text-black text-center hover-effect-wrapper">
                                                                        <div className="questions border-end w-50">
                                                                            <p className="w-100 mb-0 fs-6 text-success hover-effect">
                                                                                {challenge.noOfAttempts === 0 ? '-' : challenge.noOfAttempts}
                                                                            </p>
                                                                            <small className="text-secondary hover-effect">Attempts</small>
                                                                        </div>
                                                                        <div className="timer w-50">
                                                                            <p className="mb-0 fs-6 text-danger hover-effect">{challenge.value}</p>
                                                                            <small className="text-secondary hover-effect">Score</small>
                                                                        </div>
                                                                    </div>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                </div>
                                <div className='col-md-6'>
                                    <h4 className='text-center fw-bold mb-4'>Leaderboard</h4>
                                    {/* <div className='text-end'>
                                <div className="mb-3 d-inline-block border border-1 p-3 rounded-0">
                                    <i className="fa-solid fa-2xl fa-rotate-right mt-4 mx-3"
                                        onClick={handleRotateIconClick}
                                        style={{ transform: `rotate(${rotationAngle}deg)`, transition: "transform 0.5s" }}></i>
                                </div>
                            </div> */}
                                    {hasLeaderboardData !== null && leaderboardData.status !== null ? (
                                        <DataTable
                                            columns={columns}
                                            data={leaderboardArray}
                                            highlightOnHover
                                            pointerOnHover
                                            responsive
                                            customStyles={customStyles}
                                            pagination={10}
                                        />
                                    ) : (
                                        <div className='alert alert-info fw-bold text-center'>
                                            {leaderboardData.msg}
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* <button onClick={handleBackToChallengeLists}>Back to Challenge Lists</button> */}
                        </div>
                    </div>
                </div>

            </div>


            <Modal show={show2} size="xl" onHide={handleClose2} backdrop="static" data-bs-keyboard={false}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Nav justify variant="tabs" activeKey={activeTab} onSelect={handleTabChange}>
                        <Nav.Item>
                            <Nav.Link eventKey="Challenge">Challenge</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="Solves">Solves</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <div className='mt-4'>
                        {renderTabContent()}
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose2}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showFilePreview} onHide={closeModal}>
                <Modal.Header closeButton>
                    {/* Add a title or description for the file preview modal */}
                    <Modal.Title>File Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Render different content based on file type */}
                    {fileToPreview && (
                        <div>
                            {fileToPreview.endsWith('.png') || fileToPreview.endsWith('.jpg') || fileToPreview.endsWith('.jpeg') ? (
                                <div>
                                    <div className='col-md-12 text-center'>
                                        <img src={fileToPreview} alt="File Preview" className="img-fluid" />
                                    </div>
                                    <div className='text-center'>
                                        {/* <a href={fileToPreview} download={fileToPreview} className="btn btn-success mt-2">
                                        Download Image
                                    </a> */}

                                        <button className='btn btn-success text-center mt-2' onClick={() => downloadFile(fileToPreview)}>Download File</button>

                                    </div>
                                </div>
                            ) : fileToPreview.endsWith('.pdf') ? (
                                // You can render a PDF viewer here or simply display a link to the PDF file
                                <div>
                                    <div>
                                        <embed title="PDF Viewer" src={fileToPreview} width="100%" height="500px" />
                                    </div>
                                    <div className='text-center'>
                                        <button className='btn btn-success mt-2' onClick={() => downloadFile(fileToPreview)}>Download File</button>
                                    </div>
                                </div>
                            ) : fileToPreview.endsWith('.xlsx') ? (
                                // You can render an XLSX viewer or a download link for Excel files
                                <div className='text-center'>
                                    <button className='btn btn-success' onClick={() => downloadFile(fileToPreview)}> Download File</button>
                                </div>
                            ) : (
                                <div className='text-center'>
                                    <button className='btn btn-success' onClick={() => downloadFile(fileToPreview)}>Download File</button>
                                </div>
                            )}
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={closeModal}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal>


            {/* <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Hint Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h6>Are you sure you'd like to unlock this hint?</h6>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={fetchAndDisplayHint}>
                        Ok
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal> */}
        </div>
    )
}

export default UserAssignedChallenges