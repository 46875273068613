import Nav from 'react-bootstrap/Nav';
import { Link, useNavigate, useParams } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { Editor } from "@tinymce/tinymce-react";
import Button from 'react-bootstrap/Button';
import { API_BASE_URL, IVP_BASE_URL } from "../config/config";
import swal from "sweetalert"
import Services from '../../src/Service/Service';
import UserUtil from '../UserUtil/UserUtil';

const UpdateStandardChallenge = () => {

    const [activeTab, setActiveTab] = useState('files');
    const [filesToRemove, setFilesToRemove] = useState([]);
    const [flagData, setFlagData] = useState({});
    const [hintData, setHintData] = useState({});
    const [files, setFiles] = useState([]);
    const [editorData, setEditorData] = useState('');
    const [previewContent, setPreviewContent] = useState(null);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [show4, setShow4] = useState(false);
    const [errors, setErrors] = useState({});
    const [fileList, setFileList] = useState([]);
    const [selectedFileUrl, setSelectedFileUrl] = useState(null);
    // let flagIdCounter = 2;
    const [flagIdCounter, setFlagIdCounter] = useState(2);
    const [hintIdCounter, setHintIdCounter] = useState(1);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isEditingHint, setIsEditingHint] = useState(false);
    const [fileUrls, setFileUrls] = useState([]);
    // const [selectedFlagData, setSelectedFlagData] = useState(null);
    const { challengeId } = useParams();
    console.log("Id", challengeId);
    const editorRef = useRef(null);
    let navigate = useNavigate();
    const [challengeData, setChallengeData] = useState({
        challengeName: '',
        description: '',
        category: '',
        value: '',
        state: 'hidden',
        noOfAttempts: 0,
        link: ''
    });

    const [editingFlagId, setEditingFlagId] = useState(null); // To track the editing flag
    const [editingHintId, setEditingHintId] = useState(null);
    const [selectedFlagData, setSelectedFlagData] = useState({
        flag: '',
        isCaseSensitive: '0', // Default value for Case Sensitive dropdown
    });

    const [selectedHintData, setSelectedHintData] = useState({
        hint: '',
        value: '1', // Default value for Case Sensitive dropdown

    });


    const onCancelHandler = () => {
        // navigate("/dashboard/total-questions");
        navigate(-1);
    }


    const onhandleFileChange = (event) => {
        const newfileList = Array.from(event.target.files);
        const fileNames = newfileList.map(file => file.name);
        setSelectedFiles(fileNames);
        setFileList(newfileList);
        validateField('files', newfileList);

    }


    const handleEditHint = (hintKey) => {
        const existingHint = hintData[hintKey];
        setSelectedHintData(existingHint);

        setEditingHintId(hintKey);
        handleShow3();
    };

    const handleFlagChange = (event) => {
        const { name, value } = event.target;

        // If the name is 'isCaseSensitive' and no value is selected, default it to true
        if (name === 'isCaseSensitive') {
            setSelectedFlagData({
                ...selectedFlagData,
                [name]: value === 'true', // Set the default value as a boolean
            });
        } else {
            setSelectedFlagData({
                ...selectedFlagData,
                [name]: value,
            });
        }
    };



    const handleUpdateFlagChange = () => {
        if (selectedFlagData.flag && selectedFlagData.isCaseSensitive !== '0') {
            // Create a new flag object with the updated data
            const updatedFlag = {
                flag: selectedFlagData.flag,
                isCaseSensitive: selectedFlagData.isCaseSensitive,
            };

            // Check if we are editing or creating a flag
            if (editingFlagId !== null) {
                // Editing a flag, so update the existing flag with the same ID
                setFlagData((prevFlagData) => ({
                    ...prevFlagData,
                    [editingFlagId]: updatedFlag,
                }));
            } else {
                // Creating a new flag, generate a unique key and add it to the state
                const nextFlagIndex = Object.keys(flagData).length + 1; // Calculate the next available index
                const uniqueFlagKey = `${nextFlagIndex}`;
                console.log('Generated uniqueFlagKey:', uniqueFlagKey);

                setFlagData((prevFlagData) => ({
                    ...prevFlagData,
                    [uniqueFlagKey]: updatedFlag,
                }));
            }

            // Close the modal
            handleClose1();
            handleClose2();
        }
    };



    const removeFlag = (flagKey) => {
        const updatedFlagData = { ...flagData };
        delete updatedFlagData[flagKey];
        setFlagData(updatedFlagData);
    };

    const removeHint = (hintKey) => {
        const updatedHintData = { ...hintData };
        delete updatedHintData[hintKey];
        setHintData(updatedHintData);
    };

    useEffect(() => {
        console.log("flagData", flagData);
    }, [flagData]);


    const handleHintChange = (event) => {
        const { name, value } = event.target;
        setSelectedHintData({
            ...selectedHintData,
            [name]: value,
        });
    }



    const handleHintSubmit = () => {
        // Ensure that both hint and value are provided and valid before updating
        if (selectedHintData.hint && selectedHintData.value) {
            // Create a new hint object with the updated data
            const updatedHint = {
                hint: selectedHintData.hint,
                value: selectedHintData.value,

            };

            // Check if you are editing or creating a hint
            if (editingHintId !== null) {
                // Editing a hint, so update the existing hint with the same ID
                setHintData((prevHintData) => ({
                    ...prevHintData,
                    [editingHintId]: updatedHint,
                }));
            } else {
                // Creating a new hint, no need to generate a unique key
                setHintData((prevHintData) => ({
                    ...prevHintData,
                    [hintIdCounter]: updatedHint,
                }));
                // Update the hintIdCounter for the next hint
                setHintIdCounter((prevCounter) => prevCounter + 1);
            }

            // Reset the selectedHintData to its initial state
            setSelectedHintData({
                hint: '',
                value: '1',
            });
            // Close the modal or perform other actions as needed
            handleClose3();
        }
    };

    useEffect(() => {
        console.log("HintData", hintData);
    }, [hintData]);


    // const getEditorValue = (evt, editorName) => {
    //     const Content = evt.target.getContent({ format: "html" });

    //     if (editorName === 'description') {
    //         setEditorData(Content);
    //     } else if (editorName === 'hint') {
    //         setSelectedHintData((prevData) => ({
    //             ...prevData,
    //             hint: Content,
    //         }));
    //     }
    // };

    const getEditorValue = (content, editorName) => {
        if (editorName === 'description') {
            setEditorData(content);  // Use content directly
        } else if (editorName === 'hint') {
            setSelectedHintData((prevData) => ({
                ...prevData,
                hint: content,  // Use content directly
            }));
        }
    };




    const handleEditorChange = (content) => {
        setEditorData(content);
    };


    const handleClose1 = () => {
        setShow1(false);
    }

    const handleClose2 = () => {
        setShow2(false);
    }
    const handleClose3 = () => {
        setShow3(false);
        setSelectedHintData({
            hint: '',
            value: '1',
        });
        setEditingHintId(null); // Reset editingHintId
    }

    const handleClose4 = () => {
        setShow4(false);


    }

    const handleShow1 = (flagKey) => {
        // Set the editingFlagId when editing a flag
        setEditingFlagId(flagKey);

        // Set the selectedFlagData based on the flag being edited
        setSelectedFlagData({
            flag: flagData[flagKey].flag,
            isCaseSensitive: flagData[flagKey].isCaseSensitive ? 'true' : 'false', // Convert to string
        });

        setShow1(true);
    };

    const handleShow2 = () => {
        // Reset the editingFlagId when creating a new flag
        setEditingFlagId(null);

        // Clear the selectedFlagData
        setSelectedFlagData({
            flag: '',
            isCaseSensitive: 'true', // Initialize as "Choose Case Sensitive"
        });

        setShow2(true);
    };

    const handleShow3 = () => {
        setShow3(true);
    };

    const handleShow4 = (index) => {
        setShow4(true);

    };

    const handleTabChange = (eventKey) => {
        setActiveTab(eventKey);
    };


    // const handleRemoveFile = (indexToRemove, fileName) => {
    //     // Make a copy of the current fileUrls array
    //     const updatedFileUrls = [...fileUrls];

    //     // Remove the filename at the specified index
    //     updatedFileUrls.splice(indexToRemove, 1);

    //     // Update the state with the modified fileUrls array
    //     setFileUrls(updatedFileUrls);

    //     Services.DeleteFilesinChallenge(challengeId, fileName)
    //         .then(async response => {
    //             if (response.data.status == true) {
    //                 await swal("Success", response.data.msg, "success");

    //             } else {
    //                 await swal("Message!", response.data.msg, "info")
    //             }
    //         }).catch(async err => {
    //             if (err.response && err.response.status === 401) {
    //                 const errorMessage = err.response.data.errors.UnAuthorized;

    //                 swal("Message!", errorMessage, "warning");
    //                 UserUtil.logout();
    //             }
    //             else if (err.response && err.response.data && err.response.data.errors) {
    //                 let errorMessages = Object.values(err.response.data.errors).join('\n');
    //                 swal("Message!", errorMessages, "warning");
    //             } else if (err.response && err.response.data && err.response.data.msg) {
    //                 swal("Message!", err.response.data.msg, "warning");
    //             } else {
    //                 swal("Message!", "Server error occurred", "warning");
    //             }
    //         })

    // };



    const handleRemoveFile = (indexToRemove, fileName) => {
        // Display confirmation dialog before proceeding with deletion
        swal({
            title: "Are you sure?",
            text: "You want to delete the file",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            // If user confirms deletion
            if (willDelete) {
                // Make a copy of the current fileUrls array
                const updatedFileUrls = [...fileUrls];

                // Remove the filename at the specified index
                updatedFileUrls.splice(indexToRemove, 1);

                // Update the state with the modified fileUrls array
                setFileUrls(updatedFileUrls);

                // Call API to delete the file
                Services.DeleteFilesinChallenge(challengeId, fileName)
                    .then(async (response) => {
                        if (response.data.status == true) {
                            await swal("Success", response.data.msg, "success");
                        } else {
                            await swal("Message!", response.data.msg, "info");
                        }
                    })
                    .catch(async (err) => {
                        if (err.response && err.response.status === 401) {
                            // Handle unauthorized error
                            UserUtil.logout();
                        } else if (err.response && err.response.data && err.response.data.errors) {
                            let errorMessages = Object.values(err.response.data.errors).join('\n');
                            swal("Message!", errorMessages, "warning");
                        } else if (err.response && err.response.data && err.response.data.msg) {
                            swal("Message!", err.response.data.msg, "warning");
                        } else {
                            swal("Message!", "Server error occurred", "warning");
                        }
                    });
            } else {
                // If user cancels deletion
                swal("Your file is safe!");
            }
        });
    };


    const handleChange = (event) => {
        const { name, value } = event.target;
        // If the 'name' is 'state', update the 'state' field
        if (name === 'state') {
            setChallengeData((prevData) => ({
                ...prevData,
                state: value, // Set the state value to the selected option value
            }));
        } else {
            setChallengeData({ ...challengeData, [name]: value });
        }

    }



    const handleBlur = (e) => {
        const { name, value } = e.target;
        if (name === 'challengeName' && value.trim().length > 0) {
            validateField(name, value, 'blur');
        }
        if (name === 'category' && value.trim().length > 0) {
            validateField(name, value, 'blur');
        }

        if (name === 'description' && value.trim().length > 0) {
            validateField(name, value, 'blur');
        }

        if (name === 'value' && value.trim().length > 0) {
            validateField(name, value, 'blur');
        }
        if (name === 'link' && value.trim().length > 0) {
            validateField(name, value, 'blur');
        }

        if (name === 'noOfAttempts' && value.trim().length > 0) {
            validateField(name, value, 'blur');
        }
    };


    const validateField = (name, value) => {
        let errorsCopy = { ...errors };
        switch (name) {
            case 'challengeName':
                if (!value.trim()) {
                    errorsCopy.challengeName = 'Challenge Name is required';
                } else if (value.length > 250) {
                    errorsCopy.challengeName = 'Challenge Name must not exceed 250 characters';
                } else if (!/^[A-Za-z0-9\s]+$/.test(value)) {
                    errorsCopy.challengeName = 'Challenge Name must contain only alphanumeric characters and spaces';
                } else {
                    delete errorsCopy.challengeName;
                }
                break;

            case 'description':
                if (!value.trim()) {
                    errorsCopy.description = 'Description is required';
                }  else if (/<script\b[^>]*>(.*?)<\/script>/gi.test(value)) {
                    errorsCopy.description = 'Description must not contain script tags';
                } else {
                    delete errorsCopy.description;
                }
                break;

            case 'category': // Add validation for category
                if (value.length > 250) {
                    errorsCopy.category = 'Category must not exceed 250 characters';
                } else {
                    delete errorsCopy.category;
                }
                break;

            case 'noOfAttempts': // Add validation for category
                if (value.length > 250) {
                    errorsCopy.noOfAttempts = 'Maximum Attempts must not exceed 250 characters';
                } else {
                    delete errorsCopy.noOfAttempts;
                }
                break;

            case 'link': // Add validation for category
                if (value.length > 250) {
                    errorsCopy.link = 'Link must not exceed 250 characters';
                } else {
                    delete errorsCopy.link;
                }
                break;
            case 'value':
                if (!value.trim()) {
                    errorsCopy.value = 'Value is required';
                } else if (!/^\d+$/.test(value)) {
                    errorsCopy.value = 'Value must contain only digits';
                } else if (value.length > 250) {
                    errorsCopy.value = 'Value must not exceed 250 characters';
                } else {
                    delete errorsCopy.value;
                }
                break;

            case 'files': // Handle file validation
                const invalidFiles = [];

                value.forEach(file => {
                    if (file.size > 5 * 1024 * 1024) {
                        invalidFiles.push(file.name + ' exceeds 5MB');
                    }

                    if (!file.type || file.type === '') {
                        invalidFiles.push(file.name + ' has an unknown MIME type');
                    }

                    const fileNameParts = file.name.split('.');
                    if (fileNameParts.length > 2) {
                        invalidFiles.push(file.name + ' has a double extension');
                    }
                });

                if (invalidFiles.length > 0) {
                    errorsCopy.files = invalidFiles.join(', ');
                } else {
                    delete errorsCopy.files;
                }
                break;




        }
        setErrors(errorsCopy);
    };


    const onValidateHandler = () => {
        const { challengeName, value, category, link ,noOfAttempts} = challengeData;
        const errors = {};

        // Validate challengeName
        if (challengeName === "") {
            errors.challengeName = "Challenge Name is required";
        } else if (!/^[A-Za-z0-9\s]+$/.test(challengeName)) {
            errors.challengeName = 'Challenge Name must contain only alphanumeric characters and spaces';
        } else if (challengeName.length > 250) {
            errors.challengeName = "Challenge Name must not exceed 250 characters";
        }

        // Validate editorData (description)
        if (!editorData) {
            errors.description = "Description is required";
        } else if (/<script\b[^>]*>(.*?)<\/script>/gi.test(editorData)) {
            errors.description = "Description must not contain script tags";
        } 

        // Validate value
        if (value === "") {
            errors.value = "Value is required";
        } else if (!/^\d+$/.test(value)) {
            errors.value = 'Value must contain only digits';
        } else if (value.length > 250) {
            errors.value = "Value must not exceed 250 characters";
        }

        // Validate category
        if (category.length > 250) {
            errors.category = "Category must not exceed 250 characters";
        }

        // Validate category
        if (link.length > 250) {
            errors.category = "Link must not exceed 250 characters";
        }

        if (noOfAttempts.length > 250) {
            errors.noOfAttempts = "Maximum Attempts must not exceed 250 characters";
        }

        // Validate file sizes and extensions
        fileList.forEach(file => {
            if (file.size > 5 * 1024 * 1024) {
                errors[file.name] = `${file.name} exceeds 5MB`;
            }

            const fileNameParts = file.name.split('.');
            if (fileNameParts.length > 2) {
                errors[file.name] = `${file.name} has a double extension`;
            }
        });

        setErrors(errors);

        // Return true if no errors, false otherwise
        return Object.keys(errors).length === 0;
    }


    const extractImageNameFromUrl = (imageUrl) => {
        const parts = imageUrl.split('/');
        return parts[parts.length - 1];
    };


    const downloadFile = (fileUrl) => {
        // Make an API request to download the image using the 'imageName'
        // You can use your 'Services' to handle this API call
        const fileName = extractImageNameFromUrl(fileUrl);
        Services.DownloadFileinChallenge(fileName)
            .then(response => {
                if (response.data && response.headers['content-type']) {
                    // Extract the content type from the response headers
                    const contentType = response.headers['content-type'];

                    // Create a blob from the response data with the correct content type
                    const blob = new Blob([response.data], { type: contentType });

                    // Create a link element to trigger the download
                    const a = document.createElement('a');
                    a.href = API_BASE_URL + `/download-file/${fileName}`; // Set the desired download URL
                    a.download = fileName; // You can specify the desired filename here
                    document.body.appendChild(a);

                    // Trigger the download
                    a.click();

                    // Clean up
                    document.body.removeChild(a);
                    swal("Success", "File downloaded successfully", "success");

                }
            })
            .catch(err => {
                // Handle any errors that occur during the API request
                if (err.response && err.response.status === 401) {
                    const errorMessage = err.response.data.errors.UnAuthorized;
                    swal("Message!", errorMessage, "warning");
                    UserUtil.logout();
                } else if (err.response && err.response.data && err.response.data.errors) {
                    let errorMessages = Object.values(err.response.data.errors).join('\n');
                    swal("Message!", errorMessages, "warning");
                } else if (err.response && err.response.data && err.response.data.msg) {
                    swal("Message!", err.response.data.msg, "warning");
                } else {
                    swal("Message!", "Server error occurred", "warning");
                }
            });
    }






    const onSubmitChallengeUpdateData = () => {

        const filenamesFromUrls = fileUrls.map(url => {
            const parts = url.split('/');
            return parts[parts.length - 1];
        });

        const combinedFiles = [...fileList, ...fileUrls.map(getFileDisplayName)];
        const data = {
            ...challengeData,
            description: editorData,
            challengeType: "standard",
            flags: flagData,
            hints: hintData,
            challengeId: challengeId

        }

        console.log("Datataaa", combinedFiles);
        const UpdatedData = JSON.stringify(data);
        let formData = new FormData();
        formData.append("challenge", UpdatedData);
        combinedFiles.forEach(file => {
            formData.append('files', file);
        });

        const validationPassed = onValidateHandler();

        if (validationPassed) {

            Services.UpdateChallenge(formData)

                .then(async response => {

                    if (response.status === 200) {
                        await swal("Success", response.data.msg, "success");
                        let challengeId = response.data.id;

                        setChallengeData({
                            challengeName: '',
                            description: '',
                            category: '',
                            value: '',
                            state: '',
                            noOfAttempts: '',
                            link: ''
                        });

                        setEditorData('');
                        setSelectedFiles([]);
                        setFileList([]);
                        // setSelectedOption("standard");
                        navigate("/admin-dashboard/ctf/view-challenges");

                    }
                    else {
                        await swal("Message", response.data.msg, "info")
                    }
                }).catch(err => {
                    if (err.response && err.response.status === 401) {
                        const errorMessage = err.response.data.errors.UnAuthorized;

                        swal("Message!", errorMessage, "warning");
                        UserUtil.logout();
                    }
                    else if (err.response && err.response.data && err.response.data.errors) {
                        let errorMessages = Object.values(err.response.data.errors).join('\n');
                        swal("Message!", errorMessages, "warning");
                    } else if (err.response && err.response.data && err.response.data.msg) {
                        swal("Message!", err.response.data.msg, "warning");
                    } else {
                        swal("Message!", "Server error occurred", "warning");
                    }
                });
        }
    }

    const handlePreviewFile = (fileUrl) => {
        // Determine the file type based on the file extension
        const fileExtension = fileUrl.split('.').pop().toLowerCase();

        if (fileExtension === 'pdf') {
            // Display PDF preview using an iframe or PDF viewer library
            // Example using iframe:
            setPreviewContent(
                <>
                    <iframe
                        src={fileUrl}
                        width="100%"
                        height="500px"
                        title="PDF Preview"
                    ></iframe>
                    <div>
                        <button className='btn btn-success' onClick={() => downloadFile(fileUrl)}>Download File</button>
                    </div>
                </>
            );
        } else if (['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension)) {
            // Display image preview
            setPreviewContent(
                <>
                    <img src={fileUrl} alt="Image Preview" width={200} height={200} />
                    <div className='mt-3'>
                        <button className='btn btn-success' onClick={() => downloadFile(fileUrl)}>Download File</button>
                    </div>
                </>
            );
        }
        else {
            // Handle other file types or show an error message
            setPreviewContent(
                <div>
                    <button className='btn btn-success' onClick={() => downloadFile(fileUrl)}>Download File</button>
                </div>
            );
        }

        // Show the modal
        setShow4(true);
    };

    const getFileDisplayName = (fileUrl) => {
        if (fileUrl) {
            const parts = fileUrl.split('/');
            return parts[parts.length - 1];
        } else {
            return "Unknown File";
        }
    };


    const renderTabContent = () => {
        // Render content based on the active tab      

        if (activeTab === 'files') {
            return <div>
                <h3 className='text-center fs-4'>Files</h3>
                <div className="mb-3">
                    <h3>Choose Files:</h3>
                    <input className={errors.files ? "form-control is-invalid" : "form-control"} type="file" id="formFile" multiple onChange={onhandleFileChange} />
                    <div className='alert alert-primary'>
                        <ul className='fw-bold'>
                            <li> Attach multiple files using Control+Click or Cmd+Click</li>
                            <li>Allowed file size is upto 5 MB</li>
                        </ul>
                    </div>
                    <ul>
                        {selectedFiles.map((fileName, index) => (
                            <li className='fw-bold' key={index}>{fileName}</li>
                        ))}
                    </ul>
                    <div className="invalid-feedback">
                        {errors.files && <span>{errors.files}</span>}
                    </div>
                    {/* <ul>
                        {selectedFiles.map((fileName, index) => (
                            <li className='fw-bold' key={index}>{fileName}</li>
                        ))}
                    </ul> */}
                    <table className="table">
                        <thead>
                            <tr className='text-center'>
                                <th scope="col">File Name</th>
                                <th scope="col">Settings</th>
                            </tr>
                        </thead>
                        <tbody className='table-secondary'>
                            {fileUrls.map((fileUrl, index) => (
                                <tr key={index} className='text-center'>
                                    <td>{getFileDisplayName(fileUrl)}</td>
                                    <td>
                                        <button className="btn btn-info me-1" onClick={() => handlePreviewFile(fileUrl)}>
                                            <i className="fas fa-fw fa-eye"></i>
                                        </button>

                                        <button className="btn btn-danger me-1" onClick={() => handleRemoveFile(index, getFileDisplayName(fileUrl))}>
                                            <i className="bi bi-x"></i>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                </div>

            </div>;
        } else if (activeTab === 'flags') {
            return <div>
                <h3 className='text-center fs-4'>Flags</h3>
                <table className="table">
                    <thead>
                        <tr className='text-center'>
                            <th scope="col">Type</th>
                            <th scope="col">Flag</th>
                            <th scope="col">Settings</th>
                        </tr>
                    </thead>
                    <tbody className='table-secondary'>
                        {Object.keys(flagData).map(flagKey => (
                            <tr key={flagKey} className='text-center'>
                                <td>static</td>
                                <td>{flagData[flagKey].flag}</td>
                                <td>
                                    <button className="btn btn-warning me-1" onClick={() => handleShow1(flagKey)}><i className="fas fa-fw fa-pen"></i></button>
                                    <button className="btn btn-danger me-1" onClick={() => removeFlag(flagKey)}><i className="bi bi-x"></i></button>
                                </td>
                            </tr>
                        ))}

                    </tbody>
                </table>
                <div className='mb-2 text-end'>
                    <button className='btn-theme' onClick={handleShow2}>Create Flag</button>
                </div>

            </div>;
        } else if (activeTab === 'hints') {
            return <div>
                <h3 className='text-center fs-4'>Hints</h3>
                <table className="table">
                    <thead>
                        <tr className='text-center'>
                            <th scope="col">Hint</th>
                            <th scope="col">Cost</th>
                            <th scope="col">Settings</th>
                        </tr>
                    </thead>
                    <tbody className='table-secondary'>
                        {Object.keys(hintData).map(hintKey => (
                            <tr key={hintKey} className='text-center'>
                                <td dangerouslySetInnerHTML={{ __html: hintData[hintKey].hint }}></td>
                                <td>{hintData[hintKey].value}</td>
                                <td>
                                    <button className="btn btn-warning me-1" onClick={() => handleEditHint(hintKey)}><i className="fas fa-fw fa-pen"></i></button>
                                    <button className="btn btn-danger me-1" onClick={() => removeHint(hintKey)}><i className="bi bi-x"></i></button>
                                </td>
                            </tr>
                        ))}

                    </tbody>
                </table>
                <div className='mb-2 text-end'>
                    <button className='btn-theme' onClick={handleShow3}>Create Hint</button>
                </div>
            </div>;
        }
    };

    useEffect(() => {

        Services.GetChallengeById(challengeId)
            .then(response => {
                setChallengeData({ challengeName: response.data.challengeName, category: response.data.category, value: response.data.value, flags: response.data.flags, state: response.data.state, link: response.data.link, noOfAttempts: response.data.noOfAttempts });
                const content = response.data.description;
                setEditorData(content);
                setFlagData(response.data.flags);
                // setHintData(response.data.hints);
                const hintsFromAPI = response.data.hints;

                // Calculate the maximum hint index from the API data
                const maxHintIndex = Object.keys(hintsFromAPI).reduce((max, hintKey) => {
                    const hintIndex = parseInt(hintKey);
                    return hintIndex > max ? hintIndex : max;
                }, 0);

                // Set hintIdCounter to the next available index
                setHintIdCounter(maxHintIndex + 1);

                // Update the hintData state with hints from the API
                setHintData(hintsFromAPI);

                const fileUrl = response.data.files;
                setFileUrls(fileUrl);
                console.log("file urlsdsd", fileUrls);

            }).catch(err => {
                if (err.response && err.response.status === 401) {
                    const errorMessage = err.response.data.errors.UnAuthorized;

                    swal("Message!", errorMessage, "warning");
                    UserUtil.logout();
                }
            });
    }, [])





    return (
        <div className='Quiz-admin'>
            <section className="breadcrumbs">
                <div className="container mt-3">

                    <ol>
                        <li>
                            <Link to="/">
                                <i class="bi bi-house-door-fill"></i>
                                Home
                            </Link>
                        </li>

                        <li>
                            <Link to="/admin-dashboard/ctf">
                                Admin  Dashboard
                            </Link>
                        </li>


                        <li aria-current="page">Update Challenge</li>

                    </ol>
                    <h2>Update Challenge</h2>
                </div>
            </section>
            <div className='container wrapper'>
                <div className='row'>
                    <div className='col-md-6'>
                        <Nav justify variant="tabs" activeKey={activeTab} onSelect={handleTabChange}>
                            <Nav.Item>
                                <Nav.Link eventKey="files">Files</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="flags">Flags</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="hints">Hints</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <div className='mt-4'>
                            {renderTabContent()}
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div>
                            <div className='mb-2'>
                                <h3 className='required'>Name</h3>
                                <input
                                    type="text"
                                    className={errors.challengeName ? "form-control is-invalid" : "form-control"}
                                    id="challengeName"
                                    name='challengeName'
                                    value={challengeData.challengeName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                <div id="challengeName" className="form-text">
                                    The name of your challenge
                                </div>
                                <div className="invalid-feedback">
                                    {errors.challengeName && <span>{errors.challengeName}</span>}
                                </div>
                            </div>
                            <div className='mb-2'>
                                <h3>Category</h3>
                                <input
                                    type="text"
                                    className={errors.category ? "form-control is-invalid" : "form-control"}
                                    value={challengeData.category}
                                    id="category"
                                    name='category'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                <div id="category" className="form-text">
                                    The category of your challenge
                                </div>
                                <div className="invalid-feedback">
                                    {errors.category && <span>{errors.category}</span>}
                                </div>
                            </div>
                            <div className='mb-2'>
                                <h3 className='required'>Description</h3>
                                <Editor
                                    //apiKey="uccq416s6f5b64u1f3jtpdocbt3f7120y7w4zfmtzqxfcvyk"
                                    onInit={(evt, editor) => editorRef.current = editor}
                                    name="description"
                                    onBlur={(evt) => handleBlur({ target: { name: 'description', value: evt.target.getContent({ format: 'text' }) } })}
                                    value={editorData}
                                    onEditorChange={(val) => getEditorValue(val, 'description')}

                                    init={{
                                        height: 300,
                                        auto_focus: true,
                                        menubar: false,
                                        max_width: 300,
                                        skin: "oxide-dark",
                                        mobile: {
                                            menubar: true,
                                            theme: 'mobile'
                                        },
                                        branding: false,
                                        plugins: [
                                            'codesample', 'media', 'code', 'image'
                                        ],
                                        toolbar:
                                            'undo redo codesample code media link image| casechange blocks | bold italic backcolor | \
                                                     alignleft aligncenter alignright alignjustify | \
                                                     bullist numlist checklist outdent indent | removeformat |',
                                        elementpath: false,
                                        image_title: true,
                                        automatic_uploads: true,
                                        file_picker_types: 'image',
                                        file_picker_callback: function (cb, value, meta) {
                                            var input = document.createElement('input');
                                            input.setAttribute('type', 'file');
                                            input.setAttribute('accept', 'image/*');

                                            // Handle file selection
                                            input.onchange = function () {
                                                var file = input.files[0];
                                                var reader = new FileReader();

                                                // Read the selected file and return its URL
                                                reader.onload = function () {
                                                    var url = reader.result;
                                                    cb(url, { title: file.name });
                                                };

                                                // Read the file as a data URL
                                                reader.readAsDataURL(file);
                                            };

                                            // Trigger a click event on the file input element to open the file picker dialog
                                            input.click();
                                        },
                                        relative_urls: false,
                                        remove_script_host: false,
                                        convert_urls: true,
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                        forced_root_block: '', // Disable forcing of <p> tags on Enter
                                        force_br_newlines: true, // Enable <br> on Enter
                                        newline_behavior: 'linebreak', // Insert <br> tags for line breaks

                                    }}
                                />

                                <div className="form-text">
                                    Use this to give a brief introduction to your challenge.
                                </div>
                                {errors.description && <div className='fs-6 text-danger'>{errors.description}</div>}
                            </div>

                            <div className='mb-2'>
                                <h3>Link</h3>
                                <input
                                    type="text"
                                    className={errors.link ? "form-control is-invalid" : "form-control"}
                                    value={challengeData.link}
                                    id="link"
                                    name='link'
                                    onChange={handleChange}
                                    onBlur={handleBlur}

                                />
                                <div id="link" className="form-text">
                                    Provide valid link useful for challenge
                                </div>
                                <div className="invalid-feedback">
                                    {errors.link && <span>{errors.link}</span>}
                                </div>
                            </div>
                            <div className='mb-2'>
                                <h3>Max Attempt</h3>
                                <input
                                    type="number"
                                    className={errors.noOfAttempts ? "form-control is-invalid" : "form-control"}
                                    value={challengeData.noOfAttempts}
                                    id="noOfAttempts"
                                    name='noOfAttempts'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                <div id="noOfAttempts" className="form-text">
                                    Maximum amount of attempts users receive. Leave at 0 for unlimited.

                                </div>
                                <div className="invalid-feedback">
                                    {errors.noOfAttempts && <span>{errors.noOfAttempts}</span>}
                                </div>
                            </div>
                            <div className='mb-2'>
                                <h3 className='required'>Value</h3>
                                <input
                                    type="number"
                                    className={errors.value ? "form-control is-invalid" : "form-control"}
                                    id="value"
                                    name='value'
                                    value={challengeData.value}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                <div id="value" className="form-text">
                                    This is how many points are rewarded for solving this challenge.
                                </div>
                                <div className="invalid-feedback">
                                    {errors.value && <span>{errors.value}</span>}
                                </div>
                            </div>
                            <div className='mb-2 custom-select-quiz'>
                                <h3>State</h3>
                                <select className="form-select" name="state" aria-label="Default select example" value={challengeData.state} onChange={handleChange}>
                                    {/* <option selected="">Choose State</option> */}
                                    <option value="visible">Visible</option>
                                    <option selected value="hidden">Hidden</option>
                                </select>
                                <div className="form-text">
                                    Changes the state of the challenge (e.g. visible, hidden)
                                </div>
                            </div>
                            <div className='mb-2 text-end'>
                                {/* <div className='m-1'> */}
                                <button type="button" className="btn btn-lg btn-danger m-1" onClick={onCancelHandler}>
                                    Cancel
                                </button>
                                {/* </div> */}
                                <button type="submit" className="btn btn-lg btn-success" onClick={onSubmitChallengeUpdateData}>
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={show1} size="lg" onHide={handleClose1}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h4>Edit Flag</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='Quiz-admin'>
                    <h3>Static</h3>
                    <div id="value" className="form-text">
                        Enter static flag data
                    </div>
                    <div className='mb-2'>
                        <input
                            type="text"
                            className="form-control"
                            value={selectedFlagData.flag}
                            onChange={handleFlagChange}
                            id="Flag"
                            name='flag'
                            aria-describedby="emailHelp"

                        />
                    </div>
                    <div className='mb-2 custom-select-quiz'>
                        <h3>Case Sensitive</h3>
                        <select
                            className="form-select"
                            name="isCaseSensitive"
                            onChange={handleFlagChange}
                            value={selectedFlagData.isCaseSensitive}
                            aria-label="Default select example"
                        >
                            <option value="0">Choose Case Sensitive</option>
                            <option value="true">Case Sensitive</option>
                            <option value="false">Case Insensitive</option>
                        </select>

                    </div>
                    <div className='mx-3 text-end'>
                        <button className='btn btn-success' onClick={handleUpdateFlagChange}>Update</button>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose1}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={show2} size="lg" onHide={handleClose2}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h4>Create Flag</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='Quiz-admin'>
                    <div className='mb-2 custom-select-quiz'>
                        <h3>Choose Flag Type</h3>
                        <select className="form-select" name="isCaseSensitive" aria-label="Default select example" >
                            <option value="0" selected="">ChooseFlag Type</option>
                            <option value="static">static</option>
                        </select>
                    </div>

                    <h3>Static</h3>
                    <div id="value" className="form-text">
                        Enter static flag data
                    </div>
                    <div className='mb-2'>
                        <input
                            type="text"
                            className="form-control"
                            id="Flag"
                            name='flag'
                            onChange={handleFlagChange}
                            aria-describedby="emailHelp"
                        />
                    </div>
                    <div className='mb-2 custom-select-quiz'>
                        <h3>Case Sensitive</h3>
                        <select className="form-select" name="isCaseSensitive" onChange={handleFlagChange} aria-label="Default select example">
                            <option value="0" >Choose Case Sensitive</option>
                            <option value="true" selected>Case Sensitive</option>
                            <option value="false">Case Insensitive</option>
                        </select>
                    </div>
                    <div className='mx-3 text-end'>
                        <button className='btn btn-success' onClick={handleUpdateFlagChange}>Create Flag</button>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose2}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={show3} size="md" onHide={handleClose3}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h4>Hints</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='Quiz-admin'>
                    <div className='mb-2'>
                        <h3>Hint</h3>
                        <Editor
                            apiKey="uccq416s6f5b64u1f3jtpdocbt3f7120y7w4zfmtzqxfcvyk"
                            //onInit={(evt, editor) => editorRef.current = editor}
                            name="hint"
                            onBlur={(evt) => handleBlur({ target: { name: 'hint', value: evt.target.getContent({ format: 'text' }) } })}
                            value={selectedHintData.hint}
                            onEditorChange={(val) => getEditorValue(val, 'hint')}
                            init={{
                                height: 300,
                                auto_focus: true,
                                menubar: false,
                                max_width: 300,
                                skin: "oxide-dark",
                                mobile: {
                                    menubar: true,
                                    theme: 'mobile'
                                },
                                branding: false,
                                plugins: [
                                    'codesample', 'media', 'code', 'image'
                                ],

                                toolbar:
                                    'undo redo codesample code media link image| casechange blocks | bold italic backcolor | \
                                         alignleft aligncenter alignright alignjustify | \
                                         bullist numlist checklist outdent indent | removeformat |',
                                elementpath: false,
                                relative_urls: false,
                                remove_script_host: false,
                                convert_urls: true,
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                forced_root_block: '', // Disable forcing of <p> tags on Enter
                                force_br_newlines: true, // Enable <br> on Enter
                                newline_behavior: 'linebreak', // Insert <br> tags for line breaks

                            }}
                        />
                    </div>
                    <div className='mb-2'>
                        <h3>Cost</h3>
                        <input
                            type="number"
                            className="form-control"
                            id="value"
                            name='value'
                            value={selectedHintData.value}
                            aria-describedby="emailHelp"
                            onChange={handleHintChange}
                        />
                        <div className="form-text">
                            How many points it costs to see your hint.
                        </div>
                    </div>
                    <div className='mb-2 text-end'>
                        <button className='btn btn-success' onClick={handleHintSubmit}>Submit</button>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose3}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={show4} onHide={handleClose4}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h4>File Preview</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='Quiz-admin text-center'>
                    {previewContent}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose4}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


            {/* <Modal show={show3} size="md" onHide={handleClose3}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h4>Hints</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='Quiz-admin'>
                    <div className='mb-2'>
                        <h3>Hint</h3>
                        <Editor
                            apiKey="uccq416s6f5b64u1f3jtpdocbt3f7120y7w4zfmtzqxfcvyk"
                            //onInit={(evt, editor) => editorRef.current = editor}
                            name="description"
                            onBlur={(evt) => handleBlur({ target: { name: 'description', value: evt.target.getContent({ format: 'text' }) } })}
                            initialValue={selectedHintData.hint}
                            onChange={(evt) => getEditorValue(evt, 'hint')}
                            init={{
                                height: 300,
                                auto_focus: true,
                                menubar: false,
                                max_width: 300,
                                skin: "oxide-dark",
                                mobile: {
                                    menubar: true,
                                    theme: 'mobile'
                                },
                                branding: false,
                                plugins: [
                                    'codesample'
                                ],
                                toolbar:
                                    'undo redo | casechange blocks | bold italic backcolor | \
                                                     alignleft aligncenter alignright alignjustify | \
                                                     bullist numlist checklist outdent indent | removeformat |codesample',
                                elementpath: false,
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'

                            }}
                        />
                    </div>
                    <div className='mb-2'>
                        <h3>Cost</h3>
                        <input
                            type="number"
                            className="form-control"
                            id="value"
                            name='value'
                            value={selectedHintData.value}
                            aria-describedby="emailHelp"
                            onChange={handleHintChange}
                        />
                        <div className="form-text">
                            How many points it costs to see your hint.
                        </div>
                    </div>
                    <div className='mb-2 text-end'>
                        <button className='btn btn-success' onClick={handleHintSubmit}>Submit</button>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose3}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal> */}

        </div>
    );
}

export default UpdateStandardChallenge;