import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Services from '../../src/Service/Service';
import swal from "sweetalert"
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import DatePicker from 'react-datepicker'
import { Multiselect } from "multiselect-react-dropdown";
import UserUtil from '../UserUtil/UserUtil';


const CreateChallengeList = () => {
    let navigate = useNavigate();
    const location = useLocation();
    const [startDate, setStartDate] = useState(null);
    const [isChecked, setIsChecked] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState('');
    const [events, setEvents] = useState([]);
    const [endDate, setEndDate] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const [challengesData, setChallengesData] = useState(null);
    const [errors, setErrors] = useState({});
    const [selectedChallenges, setSelectedChallenges] = useState([]);
    const [leaderboardVisibility, setLeaderboardVisibility] = useState("");
    const [leaderboardVisibilityError, setLeaderboardVisibilityError] = useState('');
    const [challengeListData, setChallengeListData] = useState({
        challengeListName: '',
        publish: true
    });

    const { challengeListId } = useParams();
    console.log("Id", challengeListId);

    const onCancelHandler = () => {
        // navigate("/dashboard/total-questions");
        navigate(-1);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setChallengeListData({
            ...challengeListData,
            [name]: value,
        });

        if (e.target.name === "publish") {
            setChallengeListData({ ...challengeListData, [e.target.name]: !challengeListData.publish })
        }
    };


    const handleBlur = (e) => {
        const { name, value } = e.target;
        if (name === 'challengeListName' && value.trim().length > 0) {
            validateField(name, value, 'blur');
        }
        else if (name === 'selectedChallenges' && selectedChallenges.length > 0) {
            // Clear the error when challenges are selected
            setErrors((prevErrors) => ({
                ...prevErrors,
                selectedChallenges: '',
            }));
        }
    };


    const validateField = (name, value) => {
        let errorsCopy = { ...errors };
        switch (name) {
            case 'challengeListName':
                if (!value.trim()) {
                    errorsCopy.challengeListName = 'Challenge Name is required';
                } else if (!/^[A-Za-z\s]+$/.test(value)) {
                    errorsCopy.challengeListName = 'Challenge Name must contain only characters and spaces';
                } else if(value.length > 250){
                    errorsCopy.challengeListName = 'Challenge Name must not exceed 250 characters';
                }
                else {
                    delete errorsCopy.challengeListName;
                }
                break;



        }
        setErrors(errorsCopy);
    };


    const onValidateHandler = () => {
        const { challengeListName } = challengeListData;
        const errors = {};

        if (challengeListName === "") {
            errors.challengeListName = "Challenge Name is required";
        } else if (!/^[A-Za-z\s]+$/.test(challengeListName)) {
            errors.challengeName = 'Challenge Name must contain only characters and spaces';
        }

        if (selectedChallenges.length === 0) {
            errors.selectedChallenges = "Please select at least one challenge";
        }


        if (leaderboardVisibility === '') {
            errors.leaderboardVisibility = 'Leaderboard Visibility is required';
        }

        setErrors(errors);
        setLeaderboardVisibilityError(errors.leaderboardVisibility || ''); // Set leaderboardVisibilityError based on the validation
        // Return true if no errors, false otherwise
        return Object.keys(errors).length === 0;

    }

    const onSubmitChallengeListData = () => {
        const data = { ...challengeListData, challengeListId: challengeListId, startDate: startDate ? moment(startDate).format('DD/MM/YYYY, hh:mm:ss a') : 'NA', endDate: endDate ? moment(endDate).format('DD/MM/YYYY, hh:mm:ss a') : 'NA', challenges: selectedChallenges.map(challenge => challenge.name), visibility: leaderboardVisibility, event_unicode: selectedEvent, addEvent: isChecked }
        console.log("data", data);
        const validationPassed = onValidateHandler();
        if (validationPassed) {
            if (isEditMode) {
                console.log("dataedit", data)
                Services.UpdateChallengeList(data)
                    .then(async response => {
                        if (response.data.status === true) {
                            await swal("Success", response.data.msg, "success");
                            setIsEditMode(false);
                            // navigate(`/dashboard/ctf/assign-user-ctf-list/${challengeListId}`);
                            navigate(-1);

                        }
                        else {
                            await swal("Message", response.data.msg, "info");
                        }
                    }).catch(err => {
                        if (err.response && err.response.status === 401) {
                            const errorMessage = err.response.data.errors.UnAuthorized;

                            swal("Message!", errorMessage, "warning");
                            UserUtil.logout();
                        }
                        else if (err.response && err.response.data && err.response.data.errors) {
                            let errorMessages = Object.values(err.response.data.errors).join('\n');
                            swal("Message!", errorMessages, "warning");
                        } else if (err.response && err.response.data && err.response.data.msg) {
                            swal("Message!", err.response.data.msg, "warning");
                        } else {
                            swal("Message!", "Server error occurred", "warning");
                        }
                    });

            } else {


                Services.CreateChallengeList(data)

                    .then(async response => {

                        if (response.status === 200) {
                            await swal("Success", response.data.msg, "success");

                            navigate("/admin-dashboard/ctf/view-ctf-list");
                        }
                        else {
                            await swal("Message", response.data.msg, "info")
                        }
                    }).catch(err => {
                        if (err.response && err.response.status === 401) {
                            const errorMessage = err.response.data.errors.UnAuthorized;

                            swal("Message!", errorMessage, "warning");
                            UserUtil.logout();
                        }
                        else if (err.response && err.response.data && err.response.data.errors) {
                            let errorMessages = Object.values(err.response.data.errors).join('\n');
                            swal("Message!", errorMessages, "warning");
                        } else if (err.response && err.response.data && err.response.data.msg) {
                            swal("Message!", err.response.data.msg, "warning");
                        } else {
                            swal("Message!", "Server error occurred", "warning");
                        }
                    });
            }
        }
    }






    const handleStartDateChange = (date) => {
        if (date) {
            setStartDate(date);
        } else {
            setStartDate(null);
        }
    };

    const handleEndDateChange = (date) => {
        if (date) {
            setEndDate(date);
        } else {
            setEndDate(null);
        }
    };

    useEffect(() => {
        Services.GetChallengesByVisibility().then(response => {
            setChallengesData(response.data);
            console.log("data", response.data);

        }).catch(err => {
            if (err.response && err.response.status === 401) {
                const errorMessage = err.response.data.errors.UnAuthorized;

                swal("Message!", errorMessage, "warning");
                UserUtil.logout();
            }
            else {
                swal("Message!", "Server error occurred", "warning");
            }
        });

    }, [])




    useEffect(() => {

        Services.GetChallengeListById(challengeListId).then(response => {
            if (response.data.length > 0) {
                const challengeListData = response.data[0]; // Access the first object in the array
                // console.log("add event",response.data.addEvent)
                // setSelectedEvent(response.data.event_unicode);
                // if (response.data.addEvent === true) {
                //     setIsChecked(true)
                // }

                setSelectedEvent(challengeListData.event_unicode);

                // Set if the event should be added
                setIsChecked(challengeListData.addEvent === true);
                setChallengeListData({
                    challengeListName: challengeListData.challengeListName,
                    publish: challengeListData.publish
                });

                if (challengeListData.startDate !== "NA") {
                    const sDate = challengeListData.startDate;
                    const parsedStartDate = moment(sDate, 'DD/MM/YYYY, hh:mm:ss a');
                    const isoStartDate = parsedStartDate.toISOString();
                    setStartDate(new Date(isoStartDate));
                } else {
                    setStartDate(null);
                }

                if (challengeListData.endDate !== "NA") {
                    const eDate = challengeListData.endDate;
                    const parsedEndDate = moment(eDate, 'DD/MM/YYYY, hh:mm:ss a');
                    const isoEndDate = parsedEndDate.toISOString();
                    console.log("EndDate", isoEndDate);
                    setEndDate(new Date(isoEndDate));
                } else {
                    setEndDate(null);
                }

                const selectedChallenges = challengeListData.challenges.map(challenge => ({
                    name: challenge.challengeName, // Change this to match your challenge object structure

                }));


                setSelectedChallenges(selectedChallenges);
                setLeaderboardVisibility(challengeListData.visibility || "");
                setIsEditMode(true)

            }
        }).catch(err => {
            if (err.response && err.response.status === 401) {
                const errorMessage = err.response.data.errors.UnAuthorized;

                swal("Message!", errorMessage, "warning");
                UserUtil.logout();
            }
            // else {
            //     swal("Message!", "Server error occurred", "warning");
            // }
        });

    }, [])


    const challengeOptions = challengesData ? challengesData.map((challenge) => ({
        name: challenge.challengeName,
    })) : [];

    const handleSelectChallenges = (selectedList) => {
        setSelectedChallenges(selectedList);
    };
    const handleLeaderboardVisibilityChange = (e) => {
        const selectedValue = e.target.value;
        setLeaderboardVisibility(e.target.value);
        if (selectedValue === '') {
            setLeaderboardVisibilityError('Please select leaderboard visibility');
        } else {
            setLeaderboardVisibilityError('');
        }
    };


    const handleSelectChange = (event) => {
        setSelectedEvent(event.target.value);
    };


    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    useEffect(() => {
        if (isChecked) {
            Services.IVPGetEventList()
                .then(response => {
                    setEvents(response.data.data);

                }).catch(err => {
                    if (err.response && err.response.status === 401) {
                        const errorMessage = err.response.data.errors.UnAuthorized;

                        swal("Message!", errorMessage, "warning");


                        UserUtil.logout();
                    }
                });

        }
    }, [isChecked]);

    return (
        <div className='Quiz-admin'>
            <section className="breadcrumbs">
                <div className="container mt-3">

                    <ol>
                        <li>
                            <Link to="/">
                                <i class="bi bi-house-door-fill"></i>
                                Home
                            </Link>
                        </li>

                        <li>
                            <Link to="/admin-dashboard/ctf">
                                Admin  Dashboard
                            </Link>
                        </li>
                        {/* <li>
                            <Link to="/dashboard/ctf">
                                CTF
                            </Link>
                        </li> */}

                        {!isEditMode ?
                            <li>Create CTF</li>
                            :
                            <li>Update CTF</li>
                        }

                    </ol>

                    {!isEditMode ?
                        <h2>Create CTF</h2>
                        :
                        <h2>Update CTF</h2>
                    }
                </div>
            </section>
            <div className="container my-4">
                <div className='col-md-12'>
                    <div className="card rounded-0">
                        <div className="card-body">
                            <div className='mb-2'>
                                <div className='col-md-12 my-2'>
                                    <div className=" form-check">
                                        <input type="checkbox" className="form-check-input" id="exampleCheck1" checked={isChecked}
                                            onChange={handleCheckboxChange} />

                                        Do you want to map this quiz to an event

                                    </div>
                                    {isChecked && (
                                        <div className="form-group my-3 custom-select-quiz">
                                            <h3>Select Event</h3>
                                            <select className="form-control" id="eventSelect" value={selectedEvent}
                                                onChange={handleSelectChange}>
                                                <option>Select Event</option>
                                                {events.map(event => (
                                                    <option key={event.event_unicode} value={event.event_unicode}>
                                                        {event.event_name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    )}
                                </div>


                                <h3 className='required mt-3'>CTF Name</h3>
                                <input
                                    type="text"
                                    className={errors.challengeListName ? "form-control is-invalid" : "form-control"}
                                    // className='form-control'
                                    id="challengeListName"
                                    name='challengeListName'
                                    value={challengeListData.challengeListName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                <div id="challengeListName" className="form-text">
                                    The name of your challenge List
                                </div>
                                <div className="invalid-feedback">
                                    {errors.challengeListName && <span>{errors.challengeListName}</span>}
                                </div>
                            </div>
                            <div className='mb-2'>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <h3>Start Date</h3>
                                        {/* <DatePicker selected={startDate} onChange={handleStartDateChange}
                                                    isClearable
                                                    showTimeSelect
                                                    todayButton="Today"
                                                    wrapperClassName="date-picker"
                                                    dateFormat="dd/MM/yyyy, h:mm aa"

                                                /> */}


                                        <DatePicker selected={startDate}
                                            onChange={handleStartDateChange}
                                            isClearable
                                            showTimeSelect
                                            todayButton="Today"
                                            wrapperClassName="date-picker"
                                            dateFormat="dd/MM/yyyy, h:mm aa"
                                        />
                                        <div className="form-text">
                                            select start date
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <h3>End Date</h3>
                                        <DatePicker selected={endDate}
                                            onChange={handleEndDateChange}
                                            isClearable
                                            showTimeSelect
                                            todayButton="Today"
                                            wrapperClassName="date-picker"
                                            dateFormat="dd/MM/yyyy, h:mm aa"
                                        />
                                        <div className="form-text">
                                            select end date
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='mb-2'>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <h3 className='required'>Select Challenges</h3>
                                        <Multiselect
                                            options={challengeOptions}
                                            displayValue="name"
                                            selectedValues={selectedChallenges}
                                            onSelect={(selectedList) => {
                                                setSelectedChallenges(selectedList);
                                                // Clear the error when at least one challenge is selected
                                                if (selectedList.length > 0) {
                                                    setErrors((prevErrors) => ({
                                                        ...prevErrors,
                                                        selectedChallenges: '',
                                                    }));
                                                }
                                            }}
                                            onRemove={(selectedList) => {
                                                setSelectedChallenges(selectedList);
                                                // Check if there are no selected challenges and display an error
                                                if (selectedList.length === 0) {
                                                    setErrors((prevErrors) => ({
                                                        ...prevErrors,
                                                        selectedChallenges: 'Please select at least one challenge',
                                                    }));
                                                }
                                            }}
                                            showCheckbox={true}
                                            searchable={true}
                                            hidePlaceholder
                                            showArrow={true}
                                            className={errors.selectedChallenges ? 'error-border' : ''}
                                        />
                                        <div className="form-text">
                                            Select challenges to add in challenge list
                                        </div>
                                        {errors.selectedChallenges && <span className="text-danger">{errors.selectedChallenges}</span>}

                                    </div>

                                    <div className='col-md-6'>
                                        <div className='row'>

                                            <div className='col-md-6'>
                                                <div className="custom-select-quiz">
                                                    <h3>Leaderboard Visibility</h3>
                                                    <select
                                                        className={leaderboardVisibilityError ? 'form-select is-invalid' : 'form-select'}
                                                        data-title="leaderboardvisibility"
                                                        name="leaderboardvisibility"
                                                        onChange={handleLeaderboardVisibilityChange}
                                                        value={leaderboardVisibility}
                                                    >
                                                        <option value="">Choose Visibility</option>
                                                        <option value="publish">Publish</option>
                                                        <option value="unPublish">Unpublish</option>
                                                        <option value="admin">Admin Only</option>
                                                    </select>
                                                    {leaderboardVisibilityError && (
                                                        <div className="invalid-feedback">{leaderboardVisibilityError}</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <h3>Publish</h3>
                                                <div className="can-toggle">
                                                    <label className="switch text-center">
                                                        <input type="checkbox" name="publish" id="publish" value={challengeListData.publish} defaultChecked="" checked={challengeListData.publish ? true : false} onChange={handleChange} />
                                                        <span className="slider round " />
                                                        <span className="check" />
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end mt-4">
                                    {isEditMode ? (
                                        <>
                                            <div className='m-1'>
                                                <button type="button" className="btn btn-lg btn-danger" onClick={onCancelHandler}>
                                                    Cancel
                                                </button>
                                            </div>

                                            <div className='m-1'>
                                                <button type="submit" className="btn btn-lg btn-success" onClick={onSubmitChallengeListData}>
                                                    Update
                                                </button>
                                            </div>

                                        </>
                                    ) : (
                                        <button type="submit" className="btn btn-lg btn-success" onClick={onSubmitChallengeListData}>
                                            Submit
                                        </button>
                                    )}
                                </div>
                                {/* <div className='mb-2 text-end'>
                                    <button type="submit" className="btn btn-lg btn-success" onClick={onSubmitChallengeListData}>
                                        Submit
                                    </button>
                                </div> */}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateChallengeList;