import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '../src/assets/vendor/bootstrap-icons/bootstrap-icons.min.css';
import '../src/assets/vendor/remixicon/remixicon.css';
import '../src/assets/vendor/fontawesome/css/fontawesome.css';
import '../src/assets/vendor/fontawesome/css/brands.css';
import '../src/assets/vendor/fontawesome/css/solid.css';
import { GlobalLoadingProvider } from "./GlobalLoadingContext/GlobalLoadingContext";
// import 'bootstrap-icons/font/bootstrap-icons.css';
// import 'aos/dist/aos.css';
// import 'aos/dist/aos.js';
import './index.css';
import '../src/assets/css/style.css'
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google';
import myInitObject from './Login/myInitObject';
import AOS from 'aos';

AOS.init();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <GoogleOAuthProvider clientId={myInitObject.google_clientId}>
    <BrowserRouter>    
      <App />     
    </BrowserRouter>

  // </GoogleOAuthProvider>

);


reportWebVitals();
