import Button from 'react-bootstrap/Button';
import { Modal } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import Services from '../Service/Service';
import swal from 'sweetalert';
import UserUtil from '../UserUtil/UserUtil';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

const BasicDetailsModal = ({ show, handleClose }) => {
    const [dataLoaded, setDataLoaded] = useState(false);
    // let ivptok = UserUtil.getSessionStorage("__ref")
    // console.log("ivptoken",ivptok)
    let navigate = useNavigate();
    const secureFlag =
        window.location.protocol === "https:"
            ? { secure: true, sameSite: "strict" }
            : { secure: false, sameSite: "lax" };

    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        mobileno: ''
    });
    const [userData, setUserData] = useState(null);
    const [formErrors, setFormErrors] = useState({
        first_name: '',
        last_name: '',
        email: '',
        mobileno: ''
    });

    const formatFieldName = (field) => {
        return field
            .replace(/_/g, ' ') // Replace underscores with spaces
            .replace(/([A-Z])/g, ' $1') // Add space before capital letters
            .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
    };

    // Handle input change
    const handleInputChange = (e) => {
        const { id, value } = e.target;

        // Allow only digits for the mobile number field
        if (id === 'mobileno') {
            if (!/^\d*$/.test(value)) {
                setFormErrors(prevErrors => ({
                    ...prevErrors,
                    [id]: 'Mobile number can only contain digits'
                }));
                return; // Stop further execution if non-digit is entered
            } else if (value.length < 10 || value.length > 10) {
                setFormErrors(prevErrors => ({
                    ...prevErrors,
                    [id]: 'Mobile number must be exactly 10 digits'
                }));
            } else {
                // Clear the mobile number error if the value is valid
                setFormErrors(prevErrors => ({
                    ...prevErrors,
                    [id]: ''
                }));
            }
        }

        // Clear the error for other fields when valid input is entered
        if (value.trim() !== '') {
            setFormErrors(prevErrors => ({
                ...prevErrors,
                [id]: ''
            }));
        }

        setFormData(prevData => ({
            ...prevData,
            [id]: value
        }));
    };

    // Handle field validation on blur
    const handleBlur = (field) => {
        if (!formData[field]) {
            setFormErrors(prevErrors => ({
                ...prevErrors,
                [field]: `${formatFieldName(field)} is required`
            }));
        } else {
            setFormErrors(prevErrors => ({
                ...prevErrors,
                [field]: ''
            }));
        }

        // Additional validation for mobile number
        if (field === 'mobileno') {
            if (formData.mobileno.length !== 10) {
                setFormErrors(prevErrors => ({
                    ...prevErrors,
                    mobileno: 'Mobile number must be exactly 10 digits'
                }));
            } else if (!/^\d+$/.test(formData.mobileno)) {
                setFormErrors(prevErrors => ({
                    ...prevErrors,
                    mobileno: 'Mobile number can only contain digits'
                }));
            } else {
                setFormErrors(prevErrors => ({
                    ...prevErrors,
                    mobileno: ''
                }));
            }
        }
    };

//  let originalQuizId = "";
//     let quizId = ""

//     useEffect(() => {
//         quizId = UserUtil.getCookieStorage("aghvc_167");
//         console.log("quizId", quizId);
//         if (quizId) {
//             originalQuizId = quizId.substring(6, quizId.length - 6);
//             console.log("originalQuizId", originalQuizId);
//         }
//     }, []);

    const handleSubmit = () => {
        let valid = true;
        let errors = {};
        Object.keys(formData).forEach(field => {
            if (!formData[field]) {
                valid = false;
                errors[field] = `${formatFieldName(field)} is required`;
            }
        });

        setFormErrors(errors);
        const uid = UserUtil.getSessionStorage("__xyzabc")
        if (valid) {
            const dataToSend = { ...formData, uid, mode: "ctf" };
            Services.IVPUpdateUserDetails(dataToSend)
                .then(response => {
                    if (response.data.status_code === 200) {
                        swal("Success", response.data.message, "success")
                            .then(() => {
                                handleClose(); // Close the modal after showing the success message
                                UserUtil.setSessionStorage("__utp", false);
                                let user_id = UserUtil.getSessionStorage("__xyzabc")
                               
                                let data = { firstName: formData.first_name,lastName: formData.last_name, email: formData.email, name: formData.mobileno , user_id : user_id }

                                Services.IvpLogin(data)
                                    .then(response => {
                                        UserUtil.setSessionStorage("__ivpflag", 1);
                                        UserUtil.setCookies("_txyz", response.data.id, { expires: 1, ...secureFlag });
                                        // UserUtil.setSessionStorage("__xyzabc", response.data.user_id);
                                        UserUtil.setSessionStorage("IsLoggedIn", true);
                                     
                                        const flag = UserUtil.getSessionStorage("flag");
                                        const token = UserUtil.getSessionStorage("_txyz");
                
                                        const roles = getRolesFromToken(token);
                
                
                                        function getUsernameFromToken(token) {
                                            // Decode the JWT token
                                            const decodedToken = jwtDecode(token);
                
                                            // Extract the username from the decoded token
                                            const username = decodedToken ? decodedToken.sub : null;
                
                                            return username;
                                        }
                
                                        function getRolesFromToken(token) {
                                            // Decode the JWT token
                                            const decodedToken = jwtDecode(token);
                
                                            // Extract the roles from the decoded token
                                            const roles = decodedToken ? decodedToken.roles : null;
                
                                            return roles;
                                        }
                
                                        if (roles === '[ROLE_ADMIN]') {
                                            navigate('/admin-dashboard/ctf');
                                            window.location.reload();
                                        } else if (roles === '[ROLE_USER]') {
                                            navigate(`/user-dashboard/ctf`);
                                            window.location.reload();
                                        }

                                    })
                                    .catch(error => {
                                        // setIsLoading(false);
                                        if (error.response && error.response.status === 500) {
                                            swal("Server Error", error.response.data.msg, "error");
                                        } else {
                                            const errorMsg = error.response.data.msg;
                                            const errors = error.response.data.errors;
                                            let errorMessage = errorMsg + "\n\n";

                                            for (const key in errors) {
                                                if (errors.hasOwnProperty(key)) {
                                                    errorMessage += key + ": " + errors[key] + "\n";
                                                }
                                            }

                                            swal("Error occurred", errorMessage, "warning");
                                        }
                                    });

                            });
                    }
                }).catch(err => {
                    if (err.response && err.response.data.status_code === 400) {
                        const errorMessage = err.response.data.message;

                        swal("Message!", errorMessage, "warning");
                    }
                });
        }
    };


    useEffect(() => {
        const uid = UserUtil.getSessionStorage("__xyzabc")
        // let access_token = UserUtil.getSessionStorage("__ref")
        const data = { "uid": uid }
        Services.IVPGetUserDetails(data)
            .then(response => {
                if (response.data.status_code === 200) {
                    const userData = response.data.data;
                    setUserData(userData); // Store fetched user data in state
                    setFormData({
                        first_name: userData.first_name || '',
                        last_name: userData.last_name || '',
                        email: userData.email || '',
                        mobileno: userData.mobileno || ''
                    });
                    setDataLoaded(true);
                }
            }).catch(err => {
                if (err.response && err.response.status === 401) {
                    const errorMessage = err.response.data.errors.UnAuthorized;

                    swal("Message!", errorMessage, "warning");


                    UserUtil.logout();
                }
            });

    }, []);




    return (
        <div>
            <Modal
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title className="text-capitalize text-dark text-center">
                        <h5 className="fw-bold">Please provide the below details</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-5">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-floating mb-3">
                                    <input
                                        type="text"
                                        className={`form-control ${formErrors.first_name ? 'is-invalid' : ''}`}
                                        id="first_name"
                                        value={formData.first_name} // Bind to formData state
                                        onBlur={() => handleBlur('first_name')}
                                        onChange={handleInputChange}
                                        disabled={dataLoaded && !!formData.first_name && formData.first_name === userData.first_name}
                                    />
                                    <label htmlFor="firstName">
                                        First Name <span style={{ color: 'red' }}>*</span>
                                    </label>
                                    {formErrors.first_name && (
                                        <div className="invalid-feedback">
                                            {formErrors.first_name}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-floating mb-3">
                                    <input
                                        type="text"
                                        className={`form-control ${formErrors.last_name ? 'is-invalid' : ''}`}
                                        id="last_name"
                                        value={formData.last_name} // Bind to formData state
                                        onBlur={() => handleBlur('last_name')}
                                        onChange={handleInputChange}
                                        disabled={dataLoaded && userData && formData.last_name === userData.last_name}
                                    />
                                    <label htmlFor="lastName">
                                        Last Name <span style={{ color: 'red' }}>*</span>
                                    </label>
                                    {formErrors.last_name && (
                                        <div className="invalid-feedback">
                                            {formErrors.last_name}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-floating mb-3">
                                    <input
                                        type="email"
                                        className={`form-control ${formErrors.email ? 'is-invalid' : ''}`}
                                        id="email"
                                        value={formData.email} // Bind to formData state
                                        onBlur={() => handleBlur('email')}
                                        onChange={handleInputChange}
                                        disabled={dataLoaded && userData && formData.email === userData.email}
                                    />
                                    <label htmlFor="email">
                                        Email <span style={{ color: 'red' }}>*</span>
                                    </label>
                                    {formErrors.email && (
                                        <div className="invalid-feedback">
                                            {formErrors.email}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-floating mb-3">
                                    <input
                                        type="number"
                                        className={`form-control ${formErrors.mobileno ? 'is-invalid' : ''}`}
                                        id="mobileno"
                                        value={formData.mobileno} // Bind to formData state
                                        onBlur={() => handleBlur('mobileno')}
                                        onChange={handleInputChange}
                                        maxLength={10}
                                        disabled={dataLoaded && userData && formData.mobileno === userData.mobileno}
                                    />
                                    <label htmlFor="mobile">
                                        Mobile Number <span style={{ color: 'red' }}>*</span>
                                    </label>
                                    {formErrors.mobileno && (
                                        <div className="invalid-feedback">
                                            {formErrors.mobileno}
                                        </div>
                                    )}
                                </div>
                            </div>

                        </div>
                        <div className="col-md-12 text-end">
                            <button className="btn btn-success mt-3" onClick={handleSubmit}>
                                Submit
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default BasicDetailsModal