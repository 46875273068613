import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Services from '../../src/Service/Service';
import DataTable from 'react-data-table-component';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import swal from "sweetalert"
import UserUtil from '../UserUtil/UserUtil';

const ViewChallengeList = () => {
    let navigate = useNavigate();
    const [challengeListData, setChallengeListData] = useState(null);
    const [challengeData, setChallengeData] = useState([{}]);
    const [filterText, setFilterText] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [show, setShow] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    const renderTooltipEdit = (props) => (
        <Tooltip id="EditOption" {...props}>
           Edit
        </Tooltip>
    );

    const renderTooltipPreview = (props) => (
        <Tooltip id="Preview" {...props}>
            View Challenges in CTF
        </Tooltip>
    );

    const renderTooltipAddUser = (props) => (
        <Tooltip id="Preview" {...props}>
           Add Users
        </Tooltip>
    );


    const filteredDataModal = challengeData.challenges
    ? challengeData.challenges.filter((row) => {
        const lowerCaseQuery = (searchQuery || '').toLowerCase(); // Provide a default value
        return (
            row.challengeName.toLowerCase().includes(lowerCaseQuery) ||
            row.category.toLowerCase().includes(lowerCaseQuery) ||
            row.value.toString().includes(lowerCaseQuery) ||
            row.state.toLowerCase().includes(lowerCaseQuery)
        );
    })
    : [];


    const renderTooltipEditModal = (props) => (
        <Tooltip id="EditOption" {...props}>
           Edit
        </Tooltip>
    );

    const handleFilter = (e) => {
        setSearchQuery(e.target.value);
    };


    const onAddUserHandler = (challengeListId,challengeListName) =>{
        navigate(`/admin-dashboard/ctf/assign-user-ctf-list/${challengeListId}` ,{ state: { challengeListName: challengeListName } } )
    }


    const columnsModal = [
        {
            name: 'Name',
            selector: (row) => row.challengeName,
            sortable: true,
            center: true,
            
        },
        {
            name: 'Category',
            selector: (row) => row.category,
            sortable: true,
            center: true,
        },
        {
            name: 'Value',
            selector: (row) => row.value,
            sortable: true,
            center: true,
        },
        {
            name: 'State',
            selector: (row) => (
                <Badge pill bg={row.state === 'visible' ? 'success' : 'danger'}>
                    {row.state}
                </Badge>
            ),
            sortable: true,
            center: true,
        },


        {
            name: 'Options',

            cell: (row) => (
                <>

                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipEditModal}
                    >
                        <Link to={`/admin-dashboard/ctf/update-standard-challenge/${row.challengeId}`}>
                            <button className="btn btn-warning me-1">
                                <i className="fas fa-fw fa-pen" ></i>
                            </button>
                        </Link>
                    </OverlayTrigger>



                </>

            ),
            wrap: true,
            compact: true,
            grow: false,
            center: true,
        },


    ];
    const handleClose = () => setShow(false);
    const handleShow = (id) => {
        setShow(true);

        Services.GetChallengeListById(id)
            .then(response => {
                const challengeListData = response.data[0];
                const challenges = challengeListData.challenges;
                setChallengeData(prevChallengeData => ({ ...prevChallengeData, challenges }));
                console.log("fsdf", challengeData.challenges);
            }).catch((err) => {
                if (err.response && err.response.status === 401) {
                    const errorMessage = err.response.data.errors.UnAuthorized;

                    swal("Message!", errorMessage, "warning");
                    UserUtil.logout();
                }else if (err.response && err.response.status === 400) {
                    let errorMessages = Object.values(err.response.data.errors).join('\n');
                    swal("Message!", errorMessages, "warning");
                }else {
                    swal("Message!", "Server error occurred", "warning");
                }
                // alert("Server Error 500")
            })

    }
    const customStyles = {

        headCells: {
            style: {
                fontSize: '14px',
               
                //paddingLeft: '0 8px',
                //marginLeft: '10px',
                fontWeight: 'bold',
            },
        },
        cells: {
            style: {
                fontSize: '15px',
                wrap: 'false',

            },
        },
        headRow: {
            style: {
                backgroundColor: '#ced0f0',
                color: 'black',
                borderRadius:'5px'
            },
        },
    };




    const columns = [
        {
            name: 'Name',
            selector: (row) => row.challengeListName,
            sortable: true,
            center: true,
            wrap: true,
            compact: true,
            grow: false,
        },
        {
            name: 'Start Date',
            selector: (row) => row.startDate,
            sortable: true,
            center: true,
        },
        {
            name: 'End Date',
            selector: (row) => row.endDate,
            sortable: true,
            center: true,
        },
        {
            name: 'Publish',
            selector: (row) => row.publish ? 'Published' : 'Unpublished',
            sortable: true,
            maxWidth: '100px',
            center: true,
        },
        {
            name: 'Created Date',
            selector: (row) => row.createdDate,
            sortable: true,
            center: true,
        },
        {
            name: 'Updated Date',
            selector: (row) => row.updatedDate,
            sortable: true,
            center: true,
        },

        {
            name: 'Options',

            cell: (row) => (
                <>

                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipEdit}
                    >
                        <Link to={`/admin-dashboard/ctf/update-ctf-list/${row.challengeListId}`}>
                            <button className="btn btn-warning me-1">
                                <i className="fas fa-fw fa-pen" ></i>
                            </button>
                        </Link>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipPreview}
                    >
                        <button className="btn btn-info me-1">
                            <i className="fas fa-fw fa-solid fa-eye" onClick={() => handleShow(row.challengeListId)}></i>
                        </button>
                    </OverlayTrigger>

                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipAddUser}
                    >
                        {/* <Link to={`/admin-dashboard/ctf/assign-user-ctf-list/${row.challengeListId}`}> */}
                        <button className="btn btn-primary me-1"  onClick={() => onAddUserHandler(row.challengeListId ,row.challengeListName)}>
                        <i className="ri-user-add-fill"></i>
                        </button>
                        {/* </Link> */}
                    </OverlayTrigger>
                </>

            ),
            wrap: true,
            compact: true,
            grow: false,
            center: true,
        },


    ];
    useEffect(() => {
        if (challengeListData !== null) {
            const filteredData = challengeListData.filter((item) => {
                const searchData = [
                    item.challengeListName.toLowerCase(),
                    item.startDate,
                    item.endDate,
                    item.publish ? 'published' : 'unpublished',
                ].join(' ');

                return searchData.toLowerCase().includes(filterText.toLowerCase());
            });

            // Set the filtered data for rendering
            setFilteredData(filteredData);
        }
    }, [filterText, challengeListData]);


    useEffect(() => {
        Services.GetChallengeList().then(response => {
            setChallengeListData(response.data);
            console.log("data", response.data);

        }).catch(err => {
            if (err.response && err.response.status === 401) {
                const errorMessage = err.response.data.errors.UnAuthorized;

                swal("Message!", errorMessage, "warning");
                UserUtil.logout();
            }
            else {
                swal("Message!", "Server error occurred", "warning");
            }
        });

    }, [])


    return (
        <div className='Quiz-admin'>
            <section className="breadcrumbs">
                <div className="container mt-3">
                    <ol>
                        <li>
                            <Link to="/">
                            <i class="bi bi-house-door-fill"></i>
                                Home
                            </Link>
                        </li>

                        <li>
                        <Link to="/admin-dashboard/ctf">
                               Admin  Dashboard
                            </Link>
                        
                        </li>
                        {/* <li>
                            <Link to="/dashboard/ctf">
                               CTF
                            </Link>
                        </li> */}

                        <li aria-current="page">CTF List</li>

                    </ol>
                    <h2> CTF List</h2>
                </div>
            </section>
            <div className="container mt-4">
                <div className="col-md-12 d-flex justify-content-end">
                    <form className="d-flex m-2" role="search">
                        <input
                            className="form-control me-2"
                            type="search"
                            placeholder="Search"
                            aria-label="Search"
                            value={filterText}
                            onChange={(e) => setFilterText(e.target.value)}
                        />
                    </form>
                </div>
                {challengeListData !== null ? (
                    <DataTable
                        title="Challenges List"
                        columns={columns}
                        data={filteredData}
                        striped
                        highlightOnHover
                        pagination
                        paginationPerPage={10}
                        noHeader
                        responsive
                        customStyles={customStyles}
                    />
                ) : (
                    <p>Loading challenges list...</p>
                )}
            </div>


            <Modal show={show} onHide={handleClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h4>Challenges</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='Quiz-admin'>
                    <div className="col-md-12 d-flex justify-content-end">
                        <form className="d-flex m-2" role="search">
                            <input
                                className="form-control me-2"
                                type="search"
                                placeholder="Search"
                                aria-label="Search"
                                onChange={handleFilter}
                            />
                        </form>
                    </div>
                    {challengeData.challenges !== null ? (
                        <DataTable
                            title="Challenges"
                            columns={columnsModal}
                            data={filteredDataModal}
                            striped
                            highlightOnHover
                            pagination
                            paginationPerPage={10}
                            noHeader
                            responsive
                            customStyles={customStyles}
                        />
                    ) : (
                        <p>Loading challenges...</p>
                    )}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}

export default ViewChallengeList;